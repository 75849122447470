/*========================================*/
/*---------- [_Section_Team] -------------*/
/*========================================*/
.team-block {
	position: relative;
	background: #f6f6f6;
	overflow: hidden;
	border-radius: 8px;
	@include box-shadow ( 0 0 12px 2px rgba(0, 0, 0, 0.1) );
	@include transition(all 700ms ease-out 0s);
	.team-upper-part {
		img {
			border-radius: 8px 0px 0 40px;
			@include transition(all 500ms ease-out 0s);
		}
		.social-list {
			position: absolute;
			top: 30%;
			left: 0;
			right: 0;
			margin: 0 auto;
			text-align: center;
			@include transition(all 500ms ease-out 0s);
			opacity: 0;
			transform: scale(0);
		}
	}
	.team-bottom-part {
		padding: 20px;
		display: inline-block;
		width: 100%;
		text-align: center;
		z-index: 2;
		.designation {
			color: $bg-primary-color;
			@include transition(all 400ms ease-out 0s);
		}
		.social-list {
			display: inline-block;
			margin-top: 15px;
			text-align: center;
			li {
				a {
					background: transparent;
					font-size: 18px;
					color: #333;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	&:after {
		position: absolute;
		content: "";
		background: $bg-gradient-color;
		height: 8%;
		width: 12px;
		bottom: 0;
		right: 0;
		transition: 500ms;
		opacity: 0.6;
	}
	&:before {
		content: "";
		position: absolute;
		height: 70px;
		width: 80px;
		background: $bg-gradient-reverse-color;
		bottom: -30px;
		left: -50px;
		transform: rotate(45deg);
		transition: 500ms;
		opacity: 0.6;
	}
	&:hover {
		&:after {
			content: none;
		}
		&:before {
			transform: rotate(135deg);
			opacity: 0;
		}
		background: $bg-gradient-color;
		.team-upper-part {
			img {
				margin-left: 10px;
			}
		}
		.team-bottom-part {
			.social-list {
				li {
					a {
						color: #fff;
					}
				}
			}
			.team-title {
				a {
					color: #fff;
				}
			}
			.designation {
				color: #fff;
			}
		}
	}
}