/*========================================*/
/*--------------- [_Buttons] -------------*/
/*========================================*/

/*Btn Style One*/
.cs-btn-one {
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	background-color: $bg-primary-color;
	font-size: 14px;
	line-height: 26px;
	color: #ffffff;
	padding: 14px 42px;
	font-weight: 500;
	overflow: hidden;
	border-radius: 5px;
	cursor: pointer;
	&:hover{
		background: darken( $bg-primary-color, 8% );
		color: #ffffff;
		@include transition(all 300ms ease);
	}
	&.has-icon {
		i {
			font-size: 20px;
			vertical-align: middle;
			padding-right: 10px;
		}
	}
	&.btn-sm.has-icon i {
		font-size: 18px;
	}
	&.btn-circle {
		border-radius: 60px 60px 60px 60px;
	}
	&.btn-square {
		border-radius: 0;
	}
	&.btn-sm {
		font-size: 14px;
		padding: 10px 28px;
	}
	&.btn-md {
		font-size: 14px;
		padding: 18px 56px;
	}
	&.btn-lg {
		font-size: 18px;
		padding: 20px 64px;
	}
	&.btn-xl {
		font-size: 18px;
		padding: 24px 56px;
	}
	&.btn-border {
		border: 2px solid $bg-primary-color;
		background: transparent;
		color: $bg-primary-color;
	}
	&.btn-light {
		background: #fff;
		color: $bg-primary-color;
		border: 2px solid transparent;
		font-weight: 800;
		&:hover {
			border: 2px solid;
		}
		&:focus {
			box-shadow: none;
		}
	}
	&.btn-hover-border:hover {
		border: 2px solid $bg-primary-color;
	}
	&.btn-secordary-color {
		background:$bg-secondary-color;
	}
	&.btn-secordary-color:hover {
		background: $bg-primary-color;
	}
	&.btn-primary-color {
		background:$bg-primary-color;
	}
	&.btn-primary-color:hover {
		background: $bg-secondary-color;
	}
	&.btn-gradient-color {
		background:$bg-gradient-color;
		color: #fff;
		&:hover {
			background: $bg-gradient-reverse-color;
			@include box-shadow(4px 5px 10px 0px rgba(36, 106, 215, 0.6));
		}
	}
	&.btn-transparent {
		background: rgba(0,0,0,0.2);
	}
	&.text-btn {
		color: $bg-primary-color;
	}

}

// Button Small Size
