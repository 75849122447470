/* .header-style-two{

}

.header-wrapper{

} */

.topNumber{
    font-weight: 600; 
    letter-spacing: 0.05em;
}

.email-link{
    padding-right:10px;
}