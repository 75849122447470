/*========================================*/
/*--------- [_Section_Case_Study] --------*/
/*========================================*/
.case-study-item {
	.case-study-thumb {
		position: relative;
		overflow: hidden;
		img {
			width: 100%;
			-webkit-transform: scale(1.01);
			transform: scale(1.01);
			transition: all 900ms ease;
		}
		.case-study-link-icon {
			a {
				position: absolute;
				z-index: 5;
				bottom: 40px;
				right: -34px;
				width: 52px;
				margin-top: -25px;
				margin-left: -25px;
				height: 52px;
				background: $bg-gradient-color;
				text-align: center;
				line-height: 60px;
				color: #fff;
				visibility: hidden;
				opacity: 0;
				@include transition(all 500ms ease-out 0s);
			}
		}
		.case-study-details {
			position: absolute;
			width: 88%;
			margin: 0 auto;
			left: 20px;
			bottom: 10px;
			@include transition(all 500ms ease-out 0s);
			opacity: 1;
			transform-origin: bottom;
			z-index: 2;
			opacity: 0;
			.case-study-category,
			.case-study-title {
				color: #fff;
			}
		}
		&:after {
			position: absolute;
			background: linear-gradient(0deg, #11112e, transparent);
			height: 100%;
			width: 100%;
			content: "";
			left: 0;
			bottom: 0;
			opacity: 0;
			@include transition(all 500ms ease-out 0s);
		}
	}
	&:hover {
		.case-study-thumb {
			img {
				@include transform(scale(1.2) rotate(3deg));
			}
			.case-study-link-icon {
				a {
					visibility: visible;
					opacity: 1;
					right: 28px;
				}
			}
			.case-study-details {
				@include transform(perspective(0) rotateX(0));
				opacity: 1;
				bottom: 10px;
				left: 30px;
				opacity: 1;
			}
			&:after {
				opacity: 1;
			}
			&:before {
				@include transform(perspective(0) rotateX(0));
			}
		}
	}
}

// .case-study-item .case-study-thumb .case-study-details .case-study-category,
// .case-study-item .case-study-thumb .case-study-details .case-study-title, {
// 	color: #fff;
// }

.service-box {
	background: #fff;
	padding: 20px;
	text-align: center;
	margin-bottom: 30px;
	box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.07);
	position: relative;
	overflow: hidden;
	@include transition(all 300ms ease-out 0s);
}
.service-box:hover {
	@include transform(translateY(-5px));
}
.service-icon span:before {
	font-size: 60px;
	margin-top: 30px;
	display: inline-block;
	margin-bottom: 35px;
	color: $bg-primary-color;
}
.service-box .service-content .title h3 {
	font-size: 20px;
	margin-bottom: 15px;
}
.service-box:before {
	content: "";
	background: rgba(24, 49, 95, 0.8);
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	opacity: 0;
	@include transition(all 300ms ease-out 0s);
}
.service-box:after {
	position: absolute;
	background: url(../img/home/jpg/Pipeline-Analysis-Reports_1.jpg);
	// background: url(../images/bg/1.jpg);
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	content: "";
	background-size: cover;
	background-position: center center;
	opacity: 0;
	@include transition(all 300ms ease-out 0s);
}
.service-box:hover:before, .service-box:hover:after {
	opacity: 1;
}
.service-box:hover .service-icon span:before {
	position: relative;
	z-index: 2;
	color: #fff;
}
.service-box:hover .service-content .title h3 {
	color: #fff;
	position: relative;
	z-index: 2;
}
.service-box:hover .service-content .para p {
	position: relative;
	z-index: 2;
	color: #fff;
}