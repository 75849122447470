@font-face {
  font-family: 'webexicon';
  src:  url('fonts/webexicon.eot?aeef8j');
  src:  url('fonts/webexicon.eot?aeef8j#iefix') format('embedded-opentype'),
    url('fonts/webexicon.ttf?aeef8j') format('truetype'),
    url('fonts/webexicon.woff?aeef8j') format('woff'),
    url('fonts/webexicon.svg?aeef8j#webexicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="webex-icon-"], [class*=" webex-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'webexicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.5;
  font-size: 24px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.webex-icon-Agenda:before {
  content: "\e900";
}
.webex-icon-Anchor:before {
  content: "\e901";
}
.webex-icon-Antenna1:before {
  content: "\e902";
}
.webex-icon-Antenna2:before {
  content: "\e903";
}
.webex-icon-Arrow:before {
  content: "\e904";
}
.webex-icon-Astronaut:before {
  content: "\e905";
}
.webex-icon-Bag:before {
  content: "\e906";
}
.webex-icon-Bascketball:before {
  content: "\e907";
}
.webex-icon-BatteryFull:before {
  content: "\e908";
}
.webex-icon-BatteryLow:before {
  content: "\e909";
}
.webex-icon-Bicycle:before {
  content: "\e90a";
}
.webex-icon-BigTruck:before {
  content: "\e90b";
}
.webex-icon-Blog:before {
  content: "\e90c";
}
.webex-icon-Bomb:before {
  content: "\e90d";
}
.webex-icon-Book:before {
  content: "\e90e";
}
.webex-icon-BottleofWIne:before {
  content: "\e90f";
}
.webex-icon-Bourbon:before {
  content: "\e910";
}
.webex-icon-Bread:before {
  content: "\e911";
}
.webex-icon-Briefcase:before {
  content: "\e912";
}
.webex-icon-Bulb:before {
  content: "\e913";
}
.webex-icon-Calculator:before {
  content: "\e914";
}
.webex-icon-Car:before {
  content: "\e915";
}
.webex-icon-Carioca:before {
  content: "\e916";
}
.webex-icon-Cassette:before {
  content: "\e917";
}
.webex-icon-CD:before {
  content: "\e918";
}
.webex-icon-Chart:before {
  content: "\e919";
}
.webex-icon-Chart2:before {
  content: "\e91a";
}
.webex-icon-ChartDown:before {
  content: "\e91b";
}
.webex-icon-ChartUp:before {
  content: "\e91c";
}
.webex-icon-ChemicalGlass:before {
  content: "\e91d";
}
.webex-icon-Click:before {
  content: "\e91e";
}
.webex-icon-ClipboardChart:before {
  content: "\e91f";
}
.webex-icon-ClipboardText:before {
  content: "\e920";
}
.webex-icon-ClosedLock:before {
  content: "\e921";
}
.webex-icon-Cloud:before {
  content: "\e922";
}
.webex-icon-CoffeeToGo:before {
  content: "\e923";
}
.webex-icon-Column:before {
  content: "\e924";
}
.webex-icon-CompactCamera:before {
  content: "\e925";
}
.webex-icon-Compass:before {
  content: "\e926";
}
.webex-icon-CompassTool:before {
  content: "\e927";
}
.webex-icon-Controller:before {
  content: "\e928";
}
.webex-icon-Corkscrew:before {
  content: "\e929";
}
.webex-icon-Crown:before {
  content: "\e92a";
}
.webex-icon-Cup:before {
  content: "\e92b";
}
.webex-icon-Delete:before {
  content: "\e92c";
}
.webex-icon-DesktopMonitor:before {
  content: "\e92d";
}
.webex-icon-DiamondRing:before {
  content: "\e92e";
}
.webex-icon-Dislike:before {
  content: "\e92f";
}
.webex-icon-Dollar:before {
  content: "\e930";
}
.webex-icon-Dollars:before {
  content: "\e931";
}
.webex-icon-Download:before {
  content: "\e932";
}
.webex-icon-DownloadCloud:before {
  content: "\e933";
}
.webex-icon-Drop:before {
  content: "\e934";
}
.webex-icon-DSLRCamera:before {
  content: "\e935";
}
.webex-icon-Edit:before {
  content: "\e936";
}
.webex-icon-EmptyBox:before {
  content: "\e937";
}
.webex-icon-Espresso:before {
  content: "\e938";
}
.webex-icon-Exit:before {
  content: "\e939";
}
.webex-icon-ExitFullScreen:before {
  content: "\e93a";
}
.webex-icon-Export:before {
  content: "\e93b";
}
.webex-icon-Eye:before {
  content: "\e93c";
}
.webex-icon-FemaleFigure:before {
  content: "\e93d";
}
.webex-icon-File:before {
  content: "\e93e";
}
.webex-icon-FileBox:before {
  content: "\e93f";
}
.webex-icon-Files:before {
  content: "\e940";
}
.webex-icon-Flag:before {
  content: "\e941";
}
.webex-icon-Floppy:before {
  content: "\e942";
}
.webex-icon-Folder:before {
  content: "\e943";
}
.webex-icon-Footbal:before {
  content: "\e944";
}
.webex-icon-ForkandKnife:before {
  content: "\e945";
}
.webex-icon-Forward:before {
  content: "\e946";
}
.webex-icon-FullScreen:before {
  content: "\e947";
}
.webex-icon-FullShoppingCart:before {
  content: "\e948";
}
.webex-icon-Gameboy:before {
  content: "\e949";
}
.webex-icon-Glasses:before {
  content: "\e94a";
}
.webex-icon-Goto:before {
  content: "\e94b";
}
.webex-icon-Handicap:before {
  content: "\e94c";
}
.webex-icon-Hanger:before {
  content: "\e94d";
}
.webex-icon-Headset:before {
  content: "\e94e";
}
.webex-icon-Heart:before {
  content: "\e94f";
}
.webex-icon-Help:before {
  content: "\e950";
}
.webex-icon-Hourglass:before {
  content: "\e951";
}
.webex-icon-House:before {
  content: "\e952";
}
.webex-icon-Icecream1:before {
  content: "\e953";
}
.webex-icon-Icecream2:before {
  content: "\e954";
}
.webex-icon-Imbox:before {
  content: "\e955";
}
.webex-icon-Info:before {
  content: "\e956";
}
.webex-icon-Ipod:before {
  content: "\e957";
}
.webex-icon-Key:before {
  content: "\e958";
}
.webex-icon-Keyboard:before {
  content: "\e959";
}
.webex-icon-Layers:before {
  content: "\e95a";
}
.webex-icon-Like:before {
  content: "\e95b";
}
.webex-icon-Linked:before {
  content: "\e95c";
}
.webex-icon-List:before {
  content: "\e95d";
}
.webex-icon-Magnet:before {
  content: "\e95e";
}
.webex-icon-Mail:before {
  content: "\e95f";
}
.webex-icon-ManFigure:before {
  content: "\e960";
}
.webex-icon-Mastercard:before {
  content: "\e961";
}
.webex-icon-Medal:before {
  content: "\e962";
}
.webex-icon-Media:before {
  content: "\e963";
}
.webex-icon-Megaphone:before {
  content: "\e964";
}
.webex-icon-Menu:before {
  content: "\e965";
}
.webex-icon-Message:before {
  content: "\e966";
}
.webex-icon-MessageLeft:before {
  content: "\e967";
}
.webex-icon-MessageRight:before {
  content: "\e968";
}
.webex-icon-Microphone:before {
  content: "\e969";
}
.webex-icon-Microphone2:before {
  content: "\e96a";
}
.webex-icon-Mouse:before {
  content: "\e96b";
}
.webex-icon-Movie:before {
  content: "\e96c";
}
.webex-icon-Mug:before {
  content: "\e96d";
}
.webex-icon-MusicMixer:before {
  content: "\e96e";
}
.webex-icon-MusicNote:before {
  content: "\e96f";
}
.webex-icon-MusicNote2:before {
  content: "\e970";
}
.webex-icon-Mute:before {
  content: "\e971";
}
.webex-icon-Next:before {
  content: "\e972";
}
.webex-icon-Notebook:before {
  content: "\e973";
}
.webex-icon-Notes:before {
  content: "\e974";
}
.webex-icon-On-Off:before {
  content: "\e975";
}
.webex-icon-OpenedLock:before {
  content: "\e976";
}
.webex-icon-PaperClip:before {
  content: "\e977";
}
.webex-icon-Pause:before {
  content: "\e978";
}
.webex-icon-Pen:before {
  content: "\e979";
}
.webex-icon-Pencil:before {
  content: "\e97a";
}
.webex-icon-Phone:before {
  content: "\e97b";
}
.webex-icon-Phone2:before {
  content: "\e97c";
}
.webex-icon-PicnicBasket:before {
  content: "\e97d";
}
.webex-icon-Picture:before {
  content: "\e97e";
}
.webex-icon-Pill:before {
  content: "\e97f";
}
.webex-icon-Pizza:before {
  content: "\e980";
}
.webex-icon-Plaine:before {
  content: "\e981";
}
.webex-icon-Planet:before {
  content: "\e982";
}
.webex-icon-Plaster:before {
  content: "\e983";
}
.webex-icon-Play:before {
  content: "\e984";
}
.webex-icon-Podium:before {
  content: "\e985";
}
.webex-icon-Pointer:before {
  content: "\e986";
}
.webex-icon-Pool:before {
  content: "\e987";
}
.webex-icon-PowerOff:before {
  content: "\e988";
}
.webex-icon-Printer:before {
  content: "\e989";
}
.webex-icon-Pulse:before {
  content: "\e98a";
}
.webex-icon-Puzzle:before {
  content: "\e98b";
}
.webex-icon-Radio:before {
  content: "\e98c";
}
.webex-icon-Restart:before {
  content: "\e98d";
}
.webex-icon-Resume:before {
  content: "\e98e";
}
.webex-icon-Ringer:before {
  content: "\e98f";
}
.webex-icon-Rolodex:before {
  content: "\e990";
}
.webex-icon-Scisors:before {
  content: "\e991";
}
.webex-icon-Screwdriver:before {
  content: "\e992";
}
.webex-icon-Search:before {
  content: "\e993";
}
.webex-icon-Seringe:before {
  content: "\e994";
}
.webex-icon-Settings:before {
  content: "\e995";
}
.webex-icon-Shaker:before {
  content: "\e996";
}
.webex-icon-Share:before {
  content: "\e997";
}
.webex-icon-Shield:before {
  content: "\e998";
}
.webex-icon-ShoppingCart:before {
  content: "\e999";
}
.webex-icon-Signal:before {
  content: "\e99a";
}
.webex-icon-SimCard:before {
  content: "\e99b";
}
.webex-icon-Snikers:before {
  content: "\e99c";
}
.webex-icon-Soccer:before {
  content: "\e99d";
}
.webex-icon-Soda:before {
  content: "\e99e";
}
.webex-icon-SodaCan:before {
  content: "\e99f";
}
.webex-icon-Speaker:before {
  content: "\e9a0";
}
.webex-icon-Speaker2:before {
  content: "\e9a1";
}
.webex-icon-Speedometter:before {
  content: "\e9a2";
}
.webex-icon-Star:before {
  content: "\e9a3";
}
.webex-icon-Starship:before {
  content: "\e9a4";
}
.webex-icon-Starship2:before {
  content: "\e9a5";
}
.webex-icon-Stop:before {
  content: "\e9a6";
}
.webex-icon-StorageBox:before {
  content: "\e9a7";
}
.webex-icon-Tablet:before {
  content: "\e9a8";
}
.webex-icon-Tag:before {
  content: "\e9a9";
}
.webex-icon-TeaMug:before {
  content: "\e9aa";
}
.webex-icon-Telesocpe:before {
  content: "\e9ab";
}
.webex-icon-TennisBall:before {
  content: "\e9ac";
}
.webex-icon-Tie:before {
  content: "\e9ad";
}
.webex-icon-Time:before {
  content: "\e9ae";
}
.webex-icon-Timer:before {
  content: "\e9af";
}
.webex-icon-Tools:before {
  content: "\e9b0";
}
.webex-icon-Trousers:before {
  content: "\e9b1";
}
.webex-icon-Truck:before {
  content: "\e9b2";
}
.webex-icon-Tshirt:before {
  content: "\e9b3";
}
.webex-icon-TV:before {
  content: "\e9b4";
}
.webex-icon-Typing:before {
  content: "\e9b5";
}
.webex-icon-Umbrella:before {
  content: "\e9b6";
}
.webex-icon-Underpants:before {
  content: "\e9b7";
}
.webex-icon-Unlinked:before {
  content: "\e9b8";
}
.webex-icon-UploadCLoud:before {
  content: "\e9b9";
}
.webex-icon-User:before {
  content: "\e9ba";
}
.webex-icon-Users:before {
  content: "\e9bb";
}
.webex-icon-Video:before {
  content: "\e9bc";
}
.webex-icon-Volume:before {
  content: "\e9bd";
}
.webex-icon-Wallet:before {
  content: "\e9be";
}
.webex-icon-Watch:before {
  content: "\e9bf";
}
.webex-icon-Water:before {
  content: "\e9c0";
}
.webex-icon-Web:before {
  content: "\e9c1";
}
.webex-icon-Webcam:before {
  content: "\e9c2";
}
.webex-icon-Wheelbarrow:before {
  content: "\e9c3";
}
.webex-icon-WineGlass:before {
  content: "\e9c4";
}
.webex-icon-WorldGlobe:before {
  content: "\e9c5";
}
.webex-icon-WorldWide:before {
  content: "\e9c6";
}
.webex-icon-Wrench:before {
  content: "\e9c7";
}
.webex-icon-heart1:before {
  content: "\e9c8";
}
.webex-icon-cloud1:before {
  content: "\e9c9";
}
.webex-icon-star:before {
  content: "\e9ca";
}
.webex-icon-tv1:before {
  content: "\e9cb";
}
.webex-icon-sound:before {
  content: "\e9cc";
}
.webex-icon-video:before {
  content: "\e9cd";
}
.webex-icon-trash:before {
  content: "\e9ce";
}
.webex-icon-user1:before {
  content: "\e9cf";
}
.webex-icon-key1:before {
  content: "\e9d0";
}
.webex-icon-search1:before {
  content: "\e9d1";
}
.webex-icon-settings:before {
  content: "\e9d2";
}
.webex-icon-camera1:before {
  content: "\e9d3";
}
.webex-icon-tag:before {
  content: "\e9d4";
}
.webex-icon-lock1:before {
  content: "\e9d5";
}
.webex-icon-bulb:before {
  content: "\e9d6";
}
.webex-icon-pen1:before {
  content: "\e9d7";
}
.webex-icon-diamond:before {
  content: "\e9d8";
}
.webex-icon-display1:before {
  content: "\e9d9";
}
.webex-icon-location1:before {
  content: "\e9da";
}
.webex-icon-eye1:before {
  content: "\e9db";
}
.webex-icon-bubble1:before {
  content: "\e9dc";
}
.webex-icon-stack1:before {
  content: "\e9dd";
}
.webex-icon-cup:before {
  content: "\e9de";
}
.webex-icon-phone1:before {
  content: "\e9df";
}
.webex-icon-news:before {
  content: "\e9e0";
}
.webex-icon-mail1:before {
  content: "\e9e1";
}
.webex-icon-like:before {
  content: "\e9e2";
}
.webex-icon-photo:before {
  content: "\e9e3";
}
.webex-icon-note:before {
  content: "\e9e4";
}
.webex-icon-clock1:before {
  content: "\e9e5";
}
.webex-icon-paperplane:before {
  content: "\e9e6";
}
.webex-icon-params:before {
  content: "\e9e7";
}
.webex-icon-banknote:before {
  content: "\e9e8";
}
.webex-icon-data:before {
  content: "\e9e9";
}
.webex-icon-music1:before {
  content: "\e9ea";
}
.webex-icon-megaphone:before {
  content: "\e9eb";
}
.webex-icon-study:before {
  content: "\e9ec";
}
.webex-icon-lab1:before {
  content: "\e9ed";
}
.webex-icon-food:before {
  content: "\e9ee";
}
.webex-icon-t-shirt:before {
  content: "\e9ef";
}
.webex-icon-fire1:before {
  content: "\e9f0";
}
.webex-icon-clip:before {
  content: "\e9f1";
}
.webex-icon-shop:before {
  content: "\e9f2";
}
.webex-icon-calendar1:before {
  content: "\e9f3";
}
.webex-icon-wallet:before {
  content: "\e9f4";
}
.webex-icon-vynil:before {
  content: "\e9f5";
}
.webex-icon-truck1:before {
  content: "\e9f6";
}
.webex-icon-world:before {
  content: "\e9f7";
}
.webex-icon-mobile1:before {
  content: "\e000";
}
.webex-icon-laptop1:before {
  content: "\e001";
}
.webex-icon-desktop:before {
  content: "\e002";
}
.webex-icon-tablet1:before {
  content: "\e003";
}
.webex-icon-phone2:before {
  content: "\e004";
}
.webex-icon-document:before {
  content: "\e005";
}
.webex-icon-documents:before {
  content: "\e006";
}
.webex-icon-search2:before {
  content: "\e007";
}
.webex-icon-clipboard1:before {
  content: "\e008";
}
.webex-icon-newspaper1:before {
  content: "\e009";
}
.webex-icon-notebook:before {
  content: "\e00a";
}
.webex-icon-book-open:before {
  content: "\e00b";
}
.webex-icon-browser:before {
  content: "\e00c";
}
.webex-icon-calendar2:before {
  content: "\e00d";
}
.webex-icon-presentation:before {
  content: "\e00e";
}
.webex-icon-picture:before {
  content: "\e00f";
}
.webex-icon-pictures:before {
  content: "\e010";
}
.webex-icon-video1:before {
  content: "\e011";
}
.webex-icon-camera2:before {
  content: "\e012";
}
.webex-icon-printer1:before {
  content: "\e013";
}
.webex-icon-toolbox:before {
  content: "\e014";
}
.webex-icon-briefcase1:before {
  content: "\e015";
}
.webex-icon-wallet1:before {
  content: "\e016";
}
.webex-icon-gift1:before {
  content: "\e017";
}
.webex-icon-bargraph:before {
  content: "\e018";
}
.webex-icon-grid:before {
  content: "\e019";
}
.webex-icon-expand:before {
  content: "\e01a";
}
.webex-icon-focus:before {
  content: "\e01b";
}
.webex-icon-edit:before {
  content: "\e01c";
}
.webex-icon-adjustments:before {
  content: "\e01d";
}
.webex-icon-ribbon:before {
  content: "\e01e";
}
.webex-icon-hourglass:before {
  content: "\e01f";
}
.webex-icon-lock2:before {
  content: "\e020";
}
.webex-icon-megaphone1:before {
  content: "\e021";
}
.webex-icon-shield1:before {
  content: "\e022";
}
.webex-icon-trophy1:before {
  content: "\e023";
}
.webex-icon-flag1:before {
  content: "\e024";
}
.webex-icon-map1:before {
  content: "\e025";
}
.webex-icon-puzzle:before {
  content: "\e026";
}
.webex-icon-basket:before {
  content: "\e027";
}
.webex-icon-envelope:before {
  content: "\e028";
}
.webex-icon-streetsign:before {
  content: "\e029";
}
.webex-icon-telescope:before {
  content: "\e02a";
}
.webex-icon-gears:before {
  content: "\e02b";
}
.webex-icon-key3:before {
  content: "\e02c";
}
.webex-icon-paperclip:before {
  content: "\e02d";
}
.webex-icon-attachment1:before {
  content: "\e02e";
}
.webex-icon-pricetags:before {
  content: "\e02f";
}
.webex-icon-lightbulb:before {
  content: "\e030";
}
.webex-icon-layers:before {
  content: "\e031";
}
.webex-icon-pencil1:before {
  content: "\e032";
}
.webex-icon-tools:before {
  content: "\e033";
}
.webex-icon-tools-2:before {
  content: "\e034";
}
.webex-icon-scissors1:before {
  content: "\e035";
}
.webex-icon-paintbrush:before {
  content: "\e036";
}
.webex-icon-magnifying-glass:before {
  content: "\e037";
}
.webex-icon-circle-compass:before {
  content: "\e038";
}
.webex-icon-linegraph:before {
  content: "\e039";
}
.webex-icon-mic1:before {
  content: "\e03a";
}
.webex-icon-strategy:before {
  content: "\e03b";
}
.webex-icon-beaker:before {
  content: "\e03c";
}
.webex-icon-caution:before {
  content: "\e03d";
}
.webex-icon-recycle:before {
  content: "\e03e";
}
.webex-icon-anchor:before {
  content: "\e03f";
}
.webex-icon-profile-male:before {
  content: "\e040";
}
.webex-icon-profile-female:before {
  content: "\e041";
}
.webex-icon-bike:before {
  content: "\e042";
}
.webex-icon-wine:before {
  content: "\e043";
}
.webex-icon-hotairballoon:before {
  content: "\e044";
}
.webex-icon-globe:before {
  content: "\e045";
}
.webex-icon-genius:before {
  content: "\e046";
}
.webex-icon-map-pin:before {
  content: "\e047";
}
.webex-icon-dial:before {
  content: "\e048";
}
.webex-icon-chat:before {
  content: "\e049";
}
.webex-icon-heart2:before {
  content: "\e04a";
}
.webex-icon-cloud2:before {
  content: "\e04b";
}
.webex-icon-upload1:before {
  content: "\e04c";
}
.webex-icon-download1:before {
  content: "\e04d";
}
.webex-icon-target1:before {
  content: "\e04e";
}
.webex-icon-hazardous:before {
  content: "\e04f";
}
.webex-icon-piechart:before {
  content: "\e050";
}
.webex-icon-speedometer:before {
  content: "\e051";
}
.webex-icon-global:before {
  content: "\e052";
}
.webex-icon-compass1:before {
  content: "\e053";
}
.webex-icon-lifesaver:before {
  content: "\e054";
}
.webex-icon-clock3:before {
  content: "\e055";
}
.webex-icon-aperture:before {
  content: "\e056";
}
.webex-icon-quote:before {
  content: "\e057";
}
.webex-icon-scope:before {
  content: "\e058";
}
.webex-icon-alarmclock:before {
  content: "\e059";
}
.webex-icon-refresh:before {
  content: "\e05a";
}
.webex-icon-happy1:before {
  content: "\e05b";
}
.webex-icon-sad1:before {
  content: "\e05c";
}
.webex-icon-facebook1:before {
  content: "\e05d";
}
.webex-icon-twitter1:before {
  content: "\e05e";
}
.webex-icon-googleplus:before {
  content: "\e05f";
}
.webex-icon-rss1:before {
  content: "\e060";
}
.webex-icon-tumblr1:before {
  content: "\e061";
}
.webex-icon-linkedin1:before {
  content: "\e062";
}
.webex-icon-dribbble1:before {
  content: "\e063";
}
.webex-icon-home:before {
  content: "\e9f8";
}
.webex-icon-home2:before {
  content: "\e9f9";
}
.webex-icon-home3:before {
  content: "\e9fa";
}
.webex-icon-office:before {
  content: "\e9fb";
}
.webex-icon-newspaper:before {
  content: "\e9fc";
}
.webex-icon-pencil:before {
  content: "\e9fd";
}
.webex-icon-pencil2:before {
  content: "\e9fe";
}
.webex-icon-quill:before {
  content: "\e9ff";
}
.webex-icon-pen:before {
  content: "\ea00";
}
.webex-icon-blog:before {
  content: "\ea01";
}
.webex-icon-eyedropper:before {
  content: "\ea02";
}
.webex-icon-droplet:before {
  content: "\ea03";
}
.webex-icon-paint-format:before {
  content: "\ea04";
}
.webex-icon-image:before {
  content: "\ea05";
}
.webex-icon-images:before {
  content: "\ea06";
}
.webex-icon-camera:before {
  content: "\ea07";
}
.webex-icon-headphones:before {
  content: "\ea08";
}
.webex-icon-music:before {
  content: "\ea09";
}
.webex-icon-play:before {
  content: "\ea0a";
}
.webex-icon-film:before {
  content: "\ea0b";
}
.webex-icon-video-camera:before {
  content: "\ea0c";
}
.webex-icon-dice:before {
  content: "\ea0d";
}
.webex-icon-pacman:before {
  content: "\ea0e";
}
.webex-icon-spades:before {
  content: "\ea0f";
}
.webex-icon-clubs:before {
  content: "\ea10";
}
.webex-icon-diamonds:before {
  content: "\ea11";
}
.webex-icon-bullhorn:before {
  content: "\ea12";
}
.webex-icon-connection:before {
  content: "\ea13";
}
.webex-icon-podcast:before {
  content: "\ea14";
}
.webex-icon-feed:before {
  content: "\ea15";
}
.webex-icon-mic:before {
  content: "\ea16";
}
.webex-icon-book:before {
  content: "\ea17";
}
.webex-icon-books:before {
  content: "\ea18";
}
.webex-icon-library:before {
  content: "\ea19";
}
.webex-icon-file-text:before {
  content: "\ea1a";
}
.webex-icon-profile:before {
  content: "\ea1b";
}
.webex-icon-file-empty:before {
  content: "\ea1c";
}
.webex-icon-files-empty:before {
  content: "\ea1d";
}
.webex-icon-file-text2:before {
  content: "\ea1e";
}
.webex-icon-file-picture:before {
  content: "\ea1f";
}
.webex-icon-file-music:before {
  content: "\ea20";
}
.webex-icon-file-play:before {
  content: "\ea21";
}
.webex-icon-file-video:before {
  content: "\ea22";
}
.webex-icon-file-zip:before {
  content: "\ea23";
}
.webex-icon-copy:before {
  content: "\ea24";
}
.webex-icon-paste:before {
  content: "\ea25";
}
.webex-icon-stack:before {
  content: "\ea26";
}
.webex-icon-folder:before {
  content: "\ea27";
}
.webex-icon-folder-open:before {
  content: "\ea28";
}
.webex-icon-folder-plus:before {
  content: "\ea29";
}
.webex-icon-folder-minus:before {
  content: "\ea2a";
}
.webex-icon-folder-download:before {
  content: "\ea2b";
}
.webex-icon-folder-upload:before {
  content: "\ea2c";
}
.webex-icon-price-tag:before {
  content: "\ea2d";
}
.webex-icon-price-tags:before {
  content: "\ea2e";
}
.webex-icon-barcode:before {
  content: "\ea2f";
}
.webex-icon-qrcode:before {
  content: "\ea30";
}
.webex-icon-ticket:before {
  content: "\ea31";
}
.webex-icon-cart:before {
  content: "\ea32";
}
.webex-icon-coin-dollar:before {
  content: "\ea33";
}
.webex-icon-coin-euro:before {
  content: "\ea34";
}
.webex-icon-coin-pound:before {
  content: "\ea35";
}
.webex-icon-coin-yen:before {
  content: "\ea36";
}
.webex-icon-credit-card:before {
  content: "\ea37";
}
.webex-icon-calculator:before {
  content: "\ea38";
}
.webex-icon-lifebuoy:before {
  content: "\ea39";
}
.webex-icon-phone:before {
  content: "\ea3a";
}
.webex-icon-phone-hang-up:before {
  content: "\ea3b";
}
.webex-icon-address-book:before {
  content: "\ea3c";
}
.webex-icon-envelop:before {
  content: "\ea3d";
}
.webex-icon-pushpin:before {
  content: "\ea3e";
}
.webex-icon-location:before {
  content: "\ea3f";
}
.webex-icon-location2:before {
  content: "\ea40";
}
.webex-icon-compass:before {
  content: "\ea41";
}
.webex-icon-compass2:before {
  content: "\ea42";
}
.webex-icon-map:before {
  content: "\ea43";
}
.webex-icon-map2:before {
  content: "\ea44";
}
.webex-icon-history:before {
  content: "\ea45";
}
.webex-icon-clock:before {
  content: "\ea46";
}
.webex-icon-clock2:before {
  content: "\ea47";
}
.webex-icon-alarm:before {
  content: "\ea48";
}
.webex-icon-bell:before {
  content: "\ea49";
}
.webex-icon-stopwatch:before {
  content: "\ea4a";
}
.webex-icon-calendar:before {
  content: "\ea4b";
}
.webex-icon-printer:before {
  content: "\ea4c";
}
.webex-icon-keyboard:before {
  content: "\ea4d";
}
.webex-icon-display:before {
  content: "\ea4e";
}
.webex-icon-laptop:before {
  content: "\ea4f";
}
.webex-icon-mobile:before {
  content: "\ea50";
}
.webex-icon-mobile2:before {
  content: "\ea51";
}
.webex-icon-tablet:before {
  content: "\ea52";
}
.webex-icon-tv:before {
  content: "\ea53";
}
.webex-icon-drawer:before {
  content: "\ea54";
}
.webex-icon-drawer2:before {
  content: "\ea55";
}
.webex-icon-box-add:before {
  content: "\ea56";
}
.webex-icon-box-remove:before {
  content: "\ea57";
}
.webex-icon-download:before {
  content: "\ea58";
}
.webex-icon-upload:before {
  content: "\ea59";
}
.webex-icon-floppy-disk:before {
  content: "\ea5a";
}
.webex-icon-drive:before {
  content: "\ea5b";
}
.webex-icon-database:before {
  content: "\ea5c";
}
.webex-icon-undo:before {
  content: "\ea5d";
}
.webex-icon-redo:before {
  content: "\ea5e";
}
.webex-icon-undo2:before {
  content: "\ea5f";
}
.webex-icon-redo2:before {
  content: "\ea60";
}
.webex-icon-forward:before {
  content: "\ea61";
}
.webex-icon-reply:before {
  content: "\ea62";
}
.webex-icon-bubble:before {
  content: "\ea63";
}
.webex-icon-bubbles:before {
  content: "\ea64";
}
.webex-icon-bubbles2:before {
  content: "\ea65";
}
.webex-icon-bubble2:before {
  content: "\ea66";
}
.webex-icon-bubbles3:before {
  content: "\ea67";
}
.webex-icon-bubbles4:before {
  content: "\ea68";
}
.webex-icon-user:before {
  content: "\ea69";
}
.webex-icon-users:before {
  content: "\ea6a";
}
.webex-icon-user-plus:before {
  content: "\ea6b";
}
.webex-icon-user-minus:before {
  content: "\ea6c";
}
.webex-icon-user-check:before {
  content: "\ea6d";
}
.webex-icon-user-tie:before {
  content: "\ea6e";
}
.webex-icon-quotes-left:before {
  content: "\ea6f";
}
.webex-icon-quotes-right:before {
  content: "\ea70";
}
.webex-icon-hour-glass:before {
  content: "\ea71";
}
.webex-icon-spinner:before {
  content: "\ea72";
}
.webex-icon-spinner2:before {
  content: "\ea73";
}
.webex-icon-spinner3:before {
  content: "\ea74";
}
.webex-icon-spinner4:before {
  content: "\ea75";
}
.webex-icon-spinner5:before {
  content: "\ea76";
}
.webex-icon-spinner6:before {
  content: "\ea77";
}
.webex-icon-spinner7:before {
  content: "\ea78";
}
.webex-icon-spinner8:before {
  content: "\ea79";
}
.webex-icon-spinner9:before {
  content: "\ea7a";
}
.webex-icon-spinner10:before {
  content: "\ea7b";
}
.webex-icon-spinner11:before {
  content: "\ea7c";
}
.webex-icon-binoculars:before {
  content: "\ea7d";
}
.webex-icon-search:before {
  content: "\ea7e";
}
.webex-icon-zoom-in:before {
  content: "\ea7f";
}
.webex-icon-zoom-out:before {
  content: "\ea80";
}
.webex-icon-enlarge:before {
  content: "\ea81";
}
.webex-icon-shrink:before {
  content: "\ea82";
}
.webex-icon-enlarge2:before {
  content: "\ea83";
}
.webex-icon-shrink2:before {
  content: "\ea84";
}
.webex-icon-key:before {
  content: "\ea85";
}
.webex-icon-key2:before {
  content: "\ea86";
}
.webex-icon-lock:before {
  content: "\ea87";
}
.webex-icon-unlocked:before {
  content: "\ea88";
}
.webex-icon-wrench:before {
  content: "\ea89";
}
.webex-icon-equalizer:before {
  content: "\ea8a";
}
.webex-icon-equalizer2:before {
  content: "\ea8b";
}
.webex-icon-cog:before {
  content: "\ea8c";
}
.webex-icon-cogs:before {
  content: "\ea8d";
}
.webex-icon-hammer:before {
  content: "\ea8e";
}
.webex-icon-magic-wand:before {
  content: "\ea8f";
}
.webex-icon-aid-kit:before {
  content: "\ea90";
}
.webex-icon-bug:before {
  content: "\ea91";
}
.webex-icon-pie-chart:before {
  content: "\ea92";
}
.webex-icon-stats-dots:before {
  content: "\ea93";
}
.webex-icon-stats-bars:before {
  content: "\ea94";
}
.webex-icon-stats-bars2:before {
  content: "\ea95";
}
.webex-icon-trophy:before {
  content: "\ea96";
}
.webex-icon-gift:before {
  content: "\ea97";
}
.webex-icon-glass:before {
  content: "\ea98";
}
.webex-icon-glass2:before {
  content: "\ea99";
}
.webex-icon-mug:before {
  content: "\ea9a";
}
.webex-icon-spoon-knife:before {
  content: "\ea9b";
}
.webex-icon-leaf:before {
  content: "\ea9c";
}
.webex-icon-rocket:before {
  content: "\ea9d";
}
.webex-icon-meter:before {
  content: "\ea9e";
}
.webex-icon-meter2:before {
  content: "\ea9f";
}
.webex-icon-hammer2:before {
  content: "\eaa0";
}
.webex-icon-fire:before {
  content: "\eaa1";
}
.webex-icon-lab:before {
  content: "\eaa2";
}
.webex-icon-magnet:before {
  content: "\eaa3";
}
.webex-icon-bin:before {
  content: "\eaa4";
}
.webex-icon-bin2:before {
  content: "\eaa5";
}
.webex-icon-briefcase:before {
  content: "\eaa6";
}
.webex-icon-airplane:before {
  content: "\eaa7";
}
.webex-icon-truck:before {
  content: "\eaa8";
}
.webex-icon-road:before {
  content: "\eaa9";
}
.webex-icon-accessibility:before {
  content: "\eaaa";
}
.webex-icon-target:before {
  content: "\eaab";
}
.webex-icon-shield:before {
  content: "\eaac";
}
.webex-icon-power:before {
  content: "\eaad";
}
.webex-icon-switch:before {
  content: "\eaae";
}
.webex-icon-power-cord:before {
  content: "\eaaf";
}
.webex-icon-clipboard:before {
  content: "\eab0";
}
.webex-icon-list-numbered:before {
  content: "\eab1";
}
.webex-icon-list:before {
  content: "\eab2";
}
.webex-icon-list2:before {
  content: "\eab3";
}
.webex-icon-tree:before {
  content: "\eab4";
}
.webex-icon-menu:before {
  content: "\eab5";
}
.webex-icon-menu2:before {
  content: "\eab6";
}
.webex-icon-menu3:before {
  content: "\eab7";
}
.webex-icon-menu4:before {
  content: "\eab8";
}
.webex-icon-cloud:before {
  content: "\eab9";
}
.webex-icon-cloud-download:before {
  content: "\eaba";
}
.webex-icon-cloud-upload:before {
  content: "\eabb";
}
.webex-icon-cloud-check:before {
  content: "\eabc";
}
.webex-icon-download2:before {
  content: "\eabd";
}
.webex-icon-upload2:before {
  content: "\eabe";
}
.webex-icon-download3:before {
  content: "\eabf";
}
.webex-icon-upload3:before {
  content: "\eac0";
}
.webex-icon-sphere:before {
  content: "\eac1";
}
.webex-icon-earth:before {
  content: "\eac2";
}
.webex-icon-link:before {
  content: "\eac3";
}
.webex-icon-flag:before {
  content: "\eac4";
}
.webex-icon-attachment:before {
  content: "\eac5";
}
.webex-icon-eye:before {
  content: "\eac6";
}
.webex-icon-eye-plus:before {
  content: "\eac7";
}
.webex-icon-eye-minus:before {
  content: "\eac8";
}
.webex-icon-eye-blocked:before {
  content: "\eac9";
}
.webex-icon-bookmark:before {
  content: "\eaca";
}
.webex-icon-bookmarks:before {
  content: "\eacb";
}
.webex-icon-sun:before {
  content: "\eacc";
}
.webex-icon-contrast:before {
  content: "\eacd";
}
.webex-icon-brightness-contrast:before {
  content: "\eace";
}
.webex-icon-star-empty:before {
  content: "\eacf";
}
.webex-icon-star-half:before {
  content: "\ead0";
}
.webex-icon-star-full:before {
  content: "\ead1";
}
.webex-icon-heart:before {
  content: "\ead2";
}
.webex-icon-heart-broken:before {
  content: "\ead3";
}
.webex-icon-man:before {
  content: "\ead4";
}
.webex-icon-woman:before {
  content: "\ead5";
}
.webex-icon-man-woman:before {
  content: "\ead6";
}
.webex-icon-happy:before {
  content: "\ead7";
}
.webex-icon-happy2:before {
  content: "\ead8";
}
.webex-icon-smile:before {
  content: "\ead9";
}
.webex-icon-smile2:before {
  content: "\eada";
}
.webex-icon-tongue:before {
  content: "\eadb";
}
.webex-icon-tongue2:before {
  content: "\eadc";
}
.webex-icon-sad:before {
  content: "\eadd";
}
.webex-icon-sad2:before {
  content: "\eade";
}
.webex-icon-wink:before {
  content: "\eadf";
}
.webex-icon-wink2:before {
  content: "\eae0";
}
.webex-icon-grin:before {
  content: "\eae1";
}
.webex-icon-grin2:before {
  content: "\eae2";
}
.webex-icon-cool:before {
  content: "\eae3";
}
.webex-icon-cool2:before {
  content: "\eae4";
}
.webex-icon-angry:before {
  content: "\eae5";
}
.webex-icon-angry2:before {
  content: "\eae6";
}
.webex-icon-evil:before {
  content: "\eae7";
}
.webex-icon-evil2:before {
  content: "\eae8";
}
.webex-icon-shocked:before {
  content: "\eae9";
}
.webex-icon-shocked2:before {
  content: "\eaea";
}
.webex-icon-baffled:before {
  content: "\eaeb";
}
.webex-icon-baffled2:before {
  content: "\eaec";
}
.webex-icon-confused:before {
  content: "\eaed";
}
.webex-icon-confused2:before {
  content: "\eaee";
}
.webex-icon-neutral:before {
  content: "\eaef";
}
.webex-icon-neutral2:before {
  content: "\eaf0";
}
.webex-icon-hipster:before {
  content: "\eaf1";
}
.webex-icon-hipster2:before {
  content: "\eaf2";
}
.webex-icon-wondering:before {
  content: "\eaf3";
}
.webex-icon-wondering2:before {
  content: "\eaf4";
}
.webex-icon-sleepy:before {
  content: "\eaf5";
}
.webex-icon-sleepy2:before {
  content: "\eaf6";
}
.webex-icon-frustrated:before {
  content: "\eaf7";
}
.webex-icon-frustrated2:before {
  content: "\eaf8";
}
.webex-icon-crying:before {
  content: "\eaf9";
}
.webex-icon-crying2:before {
  content: "\eafa";
}
.webex-icon-point-up:before {
  content: "\eafb";
}
.webex-icon-point-right:before {
  content: "\eafc";
}
.webex-icon-point-down:before {
  content: "\eafd";
}
.webex-icon-point-left:before {
  content: "\eafe";
}
.webex-icon-warning:before {
  content: "\eaff";
}
.webex-icon-notification:before {
  content: "\eb00";
}
.webex-icon-question:before {
  content: "\eb01";
}
.webex-icon-plus:before {
  content: "\eb02";
}
.webex-icon-minus:before {
  content: "\eb03";
}
.webex-icon-info:before {
  content: "\eb04";
}
.webex-icon-cancel-circle:before {
  content: "\eb05";
}
.webex-icon-blocked:before {
  content: "\eb06";
}
.webex-icon-cross:before {
  content: "\eb07";
}
.webex-icon-checkmark:before {
  content: "\eb08";
}
.webex-icon-checkmark2:before {
  content: "\eb09";
}
.webex-icon-spell-check:before {
  content: "\eb0a";
}
.webex-icon-enter:before {
  content: "\eb0b";
}
.webex-icon-exit:before {
  content: "\eb0c";
}
.webex-icon-play2:before {
  content: "\eb0d";
}
.webex-icon-pause:before {
  content: "\eb0e";
}
.webex-icon-stop:before {
  content: "\eb0f";
}
.webex-icon-previous:before {
  content: "\eb10";
}
.webex-icon-next:before {
  content: "\eb11";
}
.webex-icon-backward:before {
  content: "\eb12";
}
.webex-icon-forward2:before {
  content: "\eb13";
}
.webex-icon-play3:before {
  content: "\eb14";
}
.webex-icon-pause2:before {
  content: "\eb15";
}
.webex-icon-stop2:before {
  content: "\eb16";
}
.webex-icon-backward2:before {
  content: "\eb17";
}
.webex-icon-forward3:before {
  content: "\eb18";
}
.webex-icon-first:before {
  content: "\eb19";
}
.webex-icon-last:before {
  content: "\eb1a";
}
.webex-icon-previous2:before {
  content: "\eb1b";
}
.webex-icon-next2:before {
  content: "\eb1c";
}
.webex-icon-eject:before {
  content: "\eb1d";
}
.webex-icon-volume-high:before {
  content: "\eb1e";
}
.webex-icon-volume-medium:before {
  content: "\eb1f";
}
.webex-icon-volume-low:before {
  content: "\eb20";
}
.webex-icon-volume-mute:before {
  content: "\eb21";
}
.webex-icon-volume-mute2:before {
  content: "\eb22";
}
.webex-icon-volume-increase:before {
  content: "\eb23";
}
.webex-icon-volume-decrease:before {
  content: "\eb24";
}
.webex-icon-loop:before {
  content: "\eb25";
}
.webex-icon-loop2:before {
  content: "\eb26";
}
.webex-icon-infinite:before {
  content: "\eb27";
}
.webex-icon-shuffle:before {
  content: "\eb28";
}
.webex-icon-arrow-up-left:before {
  content: "\eb29";
}
.webex-icon-arrow-up:before {
  content: "\eb2a";
}
.webex-icon-arrow-up-right:before {
  content: "\eb2b";
}
.webex-icon-arrow-right:before {
  content: "\eb2c";
}
.webex-icon-arrow-down-right:before {
  content: "\eb2d";
}
.webex-icon-arrow-down:before {
  content: "\eb2e";
}
.webex-icon-arrow-down-left:before {
  content: "\eb2f";
}
.webex-icon-arrow-left:before {
  content: "\eb30";
}
.webex-icon-arrow-up-left2:before {
  content: "\eb31";
}
.webex-icon-arrow-up2:before {
  content: "\eb32";
}
.webex-icon-arrow-up-right2:before {
  content: "\eb33";
}
.webex-icon-arrow-right2:before {
  content: "\eb34";
}
.webex-icon-arrow-down-right2:before {
  content: "\eb35";
}
.webex-icon-arrow-down2:before {
  content: "\eb36";
}
.webex-icon-arrow-down-left2:before {
  content: "\eb37";
}
.webex-icon-arrow-left2:before {
  content: "\eb38";
}
.webex-icon-circle-up:before {
  content: "\eb39";
}
.webex-icon-circle-right:before {
  content: "\eb3a";
}
.webex-icon-circle-down:before {
  content: "\eb3b";
}
.webex-icon-circle-left:before {
  content: "\eb3c";
}
.webex-icon-tab:before {
  content: "\eb3d";
}
.webex-icon-move-up:before {
  content: "\eb3e";
}
.webex-icon-move-down:before {
  content: "\eb3f";
}
.webex-icon-sort-alpha-asc:before {
  content: "\eb40";
}
.webex-icon-sort-alpha-desc:before {
  content: "\eb41";
}
.webex-icon-sort-numeric-asc:before {
  content: "\eb42";
}
.webex-icon-sort-numberic-desc:before {
  content: "\eb43";
}
.webex-icon-sort-amount-asc:before {
  content: "\eb44";
}
.webex-icon-sort-amount-desc:before {
  content: "\eb45";
}
.webex-icon-command:before {
  content: "\eb46";
}
.webex-icon-shift:before {
  content: "\eb47";
}
.webex-icon-ctrl:before {
  content: "\eb48";
}
.webex-icon-opt:before {
  content: "\eb49";
}
.webex-icon-checkbox-checked:before {
  content: "\eb4a";
}
.webex-icon-checkbox-unchecked:before {
  content: "\eb4b";
}
.webex-icon-radio-checked:before {
  content: "\eb4c";
}
.webex-icon-radio-checked2:before {
  content: "\eb4d";
}
.webex-icon-radio-unchecked:before {
  content: "\eb4e";
}
.webex-icon-crop:before {
  content: "\eb4f";
}
.webex-icon-make-group:before {
  content: "\eb50";
}
.webex-icon-ungroup:before {
  content: "\eb51";
}
.webex-icon-scissors:before {
  content: "\eb52";
}
.webex-icon-filter:before {
  content: "\eb53";
}
.webex-icon-font:before {
  content: "\eb54";
}
.webex-icon-ligature:before {
  content: "\eb55";
}
.webex-icon-ligature2:before {
  content: "\eb56";
}
.webex-icon-text-height:before {
  content: "\eb57";
}
.webex-icon-text-width:before {
  content: "\eb58";
}
.webex-icon-font-size:before {
  content: "\eb59";
}
.webex-icon-bold:before {
  content: "\eb5a";
}
.webex-icon-underline:before {
  content: "\eb5b";
}
.webex-icon-italic:before {
  content: "\eb5c";
}
.webex-icon-strikethrough:before {
  content: "\eb5d";
}
.webex-icon-omega:before {
  content: "\eb5e";
}
.webex-icon-sigma:before {
  content: "\eb5f";
}
.webex-icon-page-break:before {
  content: "\eb60";
}
.webex-icon-superscript:before {
  content: "\eb61";
}
.webex-icon-subscript:before {
  content: "\eb62";
}
.webex-icon-superscript2:before {
  content: "\eb63";
}
.webex-icon-subscript2:before {
  content: "\eb64";
}
.webex-icon-text-color:before {
  content: "\eb65";
}
.webex-icon-pagebreak:before {
  content: "\eb66";
}
.webex-icon-clear-formatting:before {
  content: "\eb67";
}
.webex-icon-table:before {
  content: "\eb68";
}
.webex-icon-table2:before {
  content: "\eb69";
}
.webex-icon-insert-template:before {
  content: "\eb6a";
}
.webex-icon-pilcrow:before {
  content: "\eb6b";
}
.webex-icon-ltr:before {
  content: "\eb6c";
}
.webex-icon-rtl:before {
  content: "\eb6d";
}
.webex-icon-section:before {
  content: "\eb6e";
}
.webex-icon-paragraph-left:before {
  content: "\eb6f";
}
.webex-icon-paragraph-center:before {
  content: "\eb70";
}
.webex-icon-paragraph-right:before {
  content: "\eb71";
}
.webex-icon-paragraph-justify:before {
  content: "\eb72";
}
.webex-icon-indent-increase:before {
  content: "\eb73";
}
.webex-icon-indent-decrease:before {
  content: "\eb74";
}
.webex-icon-share:before {
  content: "\eb75";
}
.webex-icon-new-tab:before {
  content: "\eb76";
}
.webex-icon-embed:before {
  content: "\eb77";
}
.webex-icon-embed2:before {
  content: "\eb78";
}
.webex-icon-terminal:before {
  content: "\eb79";
}
.webex-icon-share2:before {
  content: "\eb7a";
}
.webex-icon-mail:before {
  content: "\eb7b";
}
.webex-icon-mail2:before {
  content: "\eb7c";
}
.webex-icon-mail3:before {
  content: "\eb7d";
}
.webex-icon-mail4:before {
  content: "\eb7e";
}
.webex-icon-amazon:before {
  content: "\eb7f";
}
.webex-icon-google:before {
  content: "\eb80";
}
.webex-icon-google2:before {
  content: "\eb81";
}
.webex-icon-google3:before {
  content: "\eb82";
}
.webex-icon-google-plus:before {
  content: "\eb83";
}
.webex-icon-google-plus2:before {
  content: "\eb84";
}
.webex-icon-google-plus3:before {
  content: "\eb85";
}
.webex-icon-hangouts:before {
  content: "\eb86";
}
.webex-icon-google-drive:before {
  content: "\eb87";
}
.webex-icon-facebook:before {
  content: "\eb88";
}
.webex-icon-facebook2:before {
  content: "\eb89";
}
.webex-icon-instagram:before {
  content: "\eb8a";
}
.webex-icon-whatsapp:before {
  content: "\eb8b";
}
.webex-icon-spotify:before {
  content: "\eb8c";
}
.webex-icon-telegram:before {
  content: "\eb8d";
}
.webex-icon-twitter:before {
  content: "\eb8e";
}
.webex-icon-vine:before {
  content: "\eb8f";
}
.webex-icon-vk:before {
  content: "\eb90";
}
.webex-icon-renren:before {
  content: "\eb91";
}
.webex-icon-sina-weibo:before {
  content: "\eb92";
}
.webex-icon-rss:before {
  content: "\eb93";
}
.webex-icon-rss2:before {
  content: "\eb94";
}
.webex-icon-youtube:before {
  content: "\eb95";
}
.webex-icon-youtube2:before {
  content: "\eb96";
}
.webex-icon-twitch:before {
  content: "\eb97";
}
.webex-icon-vimeo:before {
  content: "\eb98";
}
.webex-icon-vimeo2:before {
  content: "\eb99";
}
.webex-icon-lanyrd:before {
  content: "\eb9a";
}
.webex-icon-flickr:before {
  content: "\eb9b";
}
.webex-icon-flickr2:before {
  content: "\eb9c";
}
.webex-icon-flickr3:before {
  content: "\eb9d";
}
.webex-icon-flickr4:before {
  content: "\eb9e";
}
.webex-icon-dribbble:before {
  content: "\eb9f";
}
.webex-icon-behance:before {
  content: "\eba0";
}
.webex-icon-behance2:before {
  content: "\eba1";
}
.webex-icon-deviantart:before {
  content: "\eba2";
}
.webex-icon-500px:before {
  content: "\eba3";
}
.webex-icon-steam:before {
  content: "\eba4";
}
.webex-icon-steam2:before {
  content: "\eba5";
}
.webex-icon-dropbox:before {
  content: "\eba6";
}
.webex-icon-onedrive:before {
  content: "\eba7";
}
.webex-icon-github:before {
  content: "\eba8";
}
.webex-icon-npm:before {
  content: "\eba9";
}
.webex-icon-basecamp:before {
  content: "\ebaa";
}
.webex-icon-trello:before {
  content: "\ebab";
}
.webex-icon-wordpress:before {
  content: "\ebac";
}
.webex-icon-joomla:before {
  content: "\ebad";
}
.webex-icon-ello:before {
  content: "\ebae";
}
.webex-icon-blogger:before {
  content: "\ebaf";
}
.webex-icon-blogger2:before {
  content: "\ebb0";
}
.webex-icon-tumblr:before {
  content: "\ebb1";
}
.webex-icon-tumblr2:before {
  content: "\ebb2";
}
.webex-icon-yahoo:before {
  content: "\ebb3";
}
.webex-icon-yahoo2:before {
  content: "\ebb4";
}
.webex-icon-tux:before {
  content: "\ebb5";
}
.webex-icon-appleinc:before {
  content: "\ebb6";
}
.webex-icon-finder:before {
  content: "\ebb7";
}
.webex-icon-android:before {
  content: "\ebb8";
}
.webex-icon-windows:before {
  content: "\ebb9";
}
.webex-icon-windows8:before {
  content: "\ebba";
}
.webex-icon-soundcloud:before {
  content: "\ebbb";
}
.webex-icon-soundcloud2:before {
  content: "\ebbc";
}
.webex-icon-skype:before {
  content: "\ebbd";
}
.webex-icon-reddit:before {
  content: "\ebbe";
}
.webex-icon-hackernews:before {
  content: "\ebbf";
}
.webex-icon-wikipedia:before {
  content: "\ebc0";
}
.webex-icon-linkedin:before {
  content: "\ebc1";
}
.webex-icon-linkedin2:before {
  content: "\ebc2";
}
.webex-icon-lastfm:before {
  content: "\ebc3";
}
.webex-icon-lastfm2:before {
  content: "\ebc4";
}
.webex-icon-delicious:before {
  content: "\ebc5";
}
.webex-icon-stumbleupon:before {
  content: "\ebc6";
}
.webex-icon-stumbleupon2:before {
  content: "\ebc7";
}
.webex-icon-stackoverflow:before {
  content: "\ebc8";
}
.webex-icon-pinterest:before {
  content: "\ebc9";
}
.webex-icon-pinterest2:before {
  content: "\ebca";
}
.webex-icon-xing:before {
  content: "\ebcb";
}
.webex-icon-xing2:before {
  content: "\ebcc";
}
.webex-icon-flattr:before {
  content: "\ebcd";
}
.webex-icon-foursquare:before {
  content: "\ebce";
}
.webex-icon-yelp:before {
  content: "\ebcf";
}
.webex-icon-paypal:before {
  content: "\ebd0";
}
.webex-icon-chrome:before {
  content: "\ebd1";
}
.webex-icon-firefox:before {
  content: "\ebd2";
}
.webex-icon-IE:before {
  content: "\ebd3";
}
.webex-icon-edge:before {
  content: "\ebd4";
}
.webex-icon-safari:before {
  content: "\ebd5";
}
.webex-icon-opera:before {
  content: "\ebd6";
}
.webex-icon-file-pdf:before {
  content: "\ebd7";
}
.webex-icon-file-openoffice:before {
  content: "\ebd8";
}
.webex-icon-file-word:before {
  content: "\ebd9";
}
.webex-icon-file-excel:before {
  content: "\ebda";
}
.webex-icon-libreoffice:before {
  content: "\ebdb";
}
.webex-icon-html-five:before {
  content: "\ebdc";
}
.webex-icon-html-five2:before {
  content: "\ebdd";
}
.webex-icon-css3:before {
  content: "\ebde";
}
.webex-icon-git:before {
  content: "\ebdf";
}
.webex-icon-codepen:before {
  content: "\ebe0";
}
.webex-icon-svg:before {
  content: "\ebe1";
}
.webex-icon-IcoMoon:before {
  content: "\ebe2";
}
