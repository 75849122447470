/*============================================================
 # Template Name: Fapster - Business Consulting HTML5 Template
 # Template URI: https://webextheme.com/html/fapster-html/
 # Description: Business Consulting HTML5 Template
 # Author: Fapster
 # Author URI: https://themeforest.net/user/webextheme
 # Version: 1.0
/*============================================================

============================================
------------ [CSS_INDEXING_START] ----------
============================================
## [_Google_Fonts]
## [_Imported_CSS_Plugins]
## [_Colors]
## [_Reset_Styles]
## [_Accordion]
## [_Faqs]
## [_Buttons]
## [_Typography]
## [_Global]
## [_Header]
## [_Footer]
## [_Form]
## [_widget]
## [_Home_Slider]
## [_Section_Title]
## [_Title_Lines]
## [_Page_Title]
## [_Section_About]
## [_Section_Service]
## [_Section_Single_Service]
## [_Section_Portfolio]
## [_Section_Project]
## [_Section_Single_Project]
## [_Section_Funfact]
## [_Section_Icon_Box]
## [_Section_News]
## [_Section_Team]
## [_Section_Testimonials]
## [_Section_Pricing_Tables]
## [_Section_Case_Study]
## [_Divider]
## [_Utility]
===========================================
---------- [CSS_INDEXING_END] -------------
===========================================
*/
/*========================================*/
/*----------- [_Google_Fonts] ------------*/
/*========================================*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900|Caladea:400,700");
/*========================================*/
/*-------- [_Imported_CSS_Plugins] -------*/
/*========================================*/
@import url("../css/bootstrap.min.css");
@import url("../fonts/webexflaticon/flaticon.css");
@import url("../fonts/fontawesome/css/all.css");
@import url("../fonts/webexicon/style.css");
@import url("../css/owl.carousel.min.css");
@import url("../css/magnific-popup.css");
@import url("../css/animate.css");
@import url("../css/custom-animation.css");
@import url("../css/meanmenu.min.css");
/*========================================*/
/*-------------- [_Colors] ---------------*/
/*========================================*/
/*========== Background Colors ==========*/

.nav-item{
  display: flex !important;
  align-items: center !important;
}

.bg-primary-color {
  background: #0c59db;
}

.bg-secondary-color {
  background: #17305e;
}

/*========== Text Color ==========*/
.text-primary-color {
  color: #0c59db;
}

.text-primary-color2 {
  color: #3032A2;
}

.text-primary-color3 {
  color: #FDE428;
}

.text-secondary-color {
  color: #17305e;
}

.text-secondary-color2 {
  color: #002448;
}

.heading-text-color {
  color: #151a33;
}

.bg-gradient-color {
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
}

.bg-light-gradient-color {
  background: linear-gradient(200deg, #fffdfd, rgba(168, 122, 33, 0.15)) !important;
}

.bg-light-gradient-color-with-img {
  background: url(../images/bg/abs-bg3.png), linear-gradient(200deg, #fffdfd, rgba(168, 122, 33, 0.15)) !important;
}

.bg-light-gradient-color-with-img2 {
  background: url(../images/bg/abs-bg4.png), linear-gradient(45deg, #0E90D5, #3032A2) !important;
}

/*========================================*/
/*------------ [_Reset_Styles] -----------*/
/*========================================*/
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none !important;
}

::-moz-selection {
  background: #0c59db;
  color: #fff;
  text-shadow: none;
}

::-webkit-scrollbar {
  background-color: #ccc;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #0c59db;
}

::selection {
  background: #0c59db;
  color: #fff;
  text-shadow: none;
}

ul li {
  display: inline;
}

.img-full {
  width: 100%;
}

/*========================================*/
/*-------------- [_Accordion] ------------*/
/*========================================*/
.accordion .accordion-item {
  margin-bottom: 15px;
  float: left;
  width: 100%;
}
.accordion .accordion-item .accordion-header {
  position: relative;
}
.accordion .accordion-item .accordion-header.active .title {
  background: linear-gradient(-20deg, #0E90D5, #3032A2) !important;
  color: #fff;
}
.accordion .accordion-item .accordion-header.active span {
  background: #fff !important;
}
.accordion .accordion-item .accordion-header.active span:before {
  content: "\f063";
  color: #0c59db;
}
.accordion .accordion-item .accordion-header .title {
  cursor: pointer;
  color: #253c61;
  position: relative;
  background: #edf0fa;
  margin: 0;
  padding: 24px 20px 20px 70px;
  font-size: 18px;
}
.accordion .accordion-item .accordion-header span {
  position: absolute;
  left: 20px;
  top: 20px;
  height: 30px;
  width: 30px;
  color: #ffffff;
  background: linear-gradient(-20deg, #0E90D5, #3032A2) !important;
  text-align: center;
  line-height: 30px;
  font-size: 13px;
  border-radius: 80%;
}
.accordion .accordion-item .accordion-body {
  padding: 20px;
  background: #edf0fa;
  display: none;
}
.accordion .accordion-item .accordion-body p {
  font-size: 15px;
  color: #444444;
  margin: 0px;
}
.accordion .accordion-item:nth-child(1) .accordion-body {
  display: block;
}

/*========================================*/
/*----------------- [_Faqs] --------------*/
/*========================================*/
.faq-title {
  font-size: 64px;
  line-height: 74px;
}

/*========================================*/
/*--------------- [_Buttons] -------------*/
/*========================================*/
/*Btn Style One*/
.cs-btn-one {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  background-color: #0c59db;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  padding: 14px 42px;
  font-weight: 500;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
}
.cs-btn-one:hover {
  background: #0a49b4;
  color: #ffffff;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.cs-btn-one.has-icon i {
  font-size: 20px;
  vertical-align: middle;
  padding-right: 10px;
}
.cs-btn-one.btn-sm.has-icon i {
  font-size: 18px;
}
.cs-btn-one.btn-circle {
  border-radius: 60px 60px 60px 60px;
}
.cs-btn-one.btn-square {
  border-radius: 0;
}
.cs-btn-one.btn-sm {
  font-size: 14px;
  padding: 10px 28px;
}
.cs-btn-one.btn-md {
  font-size: 14px;
  padding: 18px 56px;
}
.cs-btn-one.btn-lg {
  font-size: 18px;
  padding: 20px 64px;
}
.cs-btn-one.btn-xl {
  font-size: 18px;
  padding: 24px 56px;
}
.cs-btn-one.btn-border {
  border: 2px solid #0c59db;
  background: transparent;
  color: #0c59db;
}
.cs-btn-one.btn-light {
  background: #fff;
  color: #0c59db;
  border: 2px solid transparent;
  font-weight: 800;
}
.cs-btn-one.btn-light:hover {
  border: 2px solid;
}
.cs-btn-one.btn-light:focus {
  box-shadow: none;
}
.cs-btn-one.btn-hover-border:hover {
  border: 2px solid #0c59db;
}
.cs-btn-one.btn-secordary-color {
  background: #17305e;
}
.cs-btn-one.btn-secordary-color:hover {
  background: #0c59db;
}
.cs-btn-one.btn-primary-color {
  background: #0c59db;
}
.cs-btn-one.btn-primary-color:hover {
  background: #17305e;
}
.cs-btn-one.btn-gradient-color {
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  color: #fff;
}
.cs-btn-one.btn-gradient-color:hover {
  background: linear-gradient(-20deg, #0E90D5, #3032A2) !important;
  -webkit-box-shadow: 4px 5px 10px 0px rgba(36, 106, 215, 0.6);
  -khtml-box-shadow: 4px 5px 10px 0px rgba(36, 106, 215, 0.6);
  -moz-box-shadow: 4px 5px 10px 0px rgba(36, 106, 215, 0.6);
  -ms-box-shadow: 4px 5px 10px 0px rgba(36, 106, 215, 0.6);
  -o-box-shadow: 4px 5px 10px 0px rgba(36, 106, 215, 0.6);
  box-shadow: 4px 5px 10px 0px rgba(36, 106, 215, 0.6);
}
.cs-btn-one.btn-transparent {
  background: rgba(0, 0, 0, 0.2);
}
.cs-btn-one.text-btn {
  color: #0c59db;
}

/*========================================*/
/*-------------- [_Utility] -----===------*/
/*========================================*/
body {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0 0 20px;
  line-height: 1.8em;
}

h1, h2, h3, h4, h5, h6 {
  color: #151a33;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

h1, h2 {
  font-weight: 800;
}

h3, h4 {
  font-weight: 700;
}

h5, h6 {
  font-weight: 600;
}

h1 {
  font-size: 48px;
  line-height: 56px;
}

h2 {
  font-size: 42px;
  line-height: 48px;
}

h3 {
  font-size: 28px;
  line-height: 1.25;
  letter-spacing: -.02em;
}

h4 {
  font-size: 22px;
  line-height: 32px;
}

h5 {
  font-size: 18px;
  line-height: 24px;
}

h6 {
  font-size: 16px;
  line-height: 26px;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
}

b, strong {
  color: #151a33;
}

iframe {
  border: none;
}

a, b, div, ul, li {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -moz-outline-: none;
}

input, select,
textarea,
input[type="text"],
input[type="date"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="tel"],
button,
button[type="submit"] {
  -moz-appearance: none;
  box-shadow: none !important;
}

img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

a {
  transition: all 0.3s ease 0s;
  text-decoration: none;
  cursor: pointer;
  color: #049AD3;
}

a:hover, a:focus, a:visited {
  text-decoration: none;
}

a:hover {
  color: #0c59db;
  text-decoration: none;
}

ul, ol {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

img {
  border: none;
  max-width: 100%;
}

.clear-both:before,
.clear-both:after {
  display: table;
  content: "";
  clear: both;
}

.block-quote {
  padding: 30px;
  background: #F4F5F8;
  border-left: 5px solid #0c59db;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.block-quote:after {
  position: absolute;
  content: "\ea70";
  font-family: 'webexicon' !important;
  font-size: 17rem;
  top: 50px;
  right: -15px;
  opacity: 0.08;
}

.block-quote p {
  font-size: 20px;
  color: #555362;
}

/*========================================*/
/*--------------- [_Global] --------------*/
/*========================================*/
.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../images/preloader.svg);
  background-size: 90px;
}

.section-gap {
  padding: 110px 0;
}

.section-gap-onepage {
  padding: 210px 0px 110px 0px !important;
}

section.anim-object {
  overflow: hidden;
}

.border-radius-default {
  border-radius: 6px;
}

.bg-pos-center-bottom {
  background-position: center bottom;
}

.table {
  display: table;
  width: 100%;
  height: 100%;
}

.table-cell {
  display: table-cell;
  width: 100%;
  height: auto;
  vertical-align: middle;
}

/* .slide-bg-1 {
  background-image: url("");
}

.slide-bg-2 {
  background-image: url("");
}

.slide-bg-3 {
  background-image: url("");
} */

.homepage-slides .owl-nav button.owl-prev,
.homepage-slides .owl-nav button.owl-next {
  font-size: 20px;
  height: 50px;
  left: 50px;
  line-height: 50px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 50px;
  background: #fff;
  transform: translateY(-50%);
  transition: all 600ms ease 0ms;
  opacity: 0;
  visibility: hidden;
}

.homepage-slides:hover .owl-nav button.owl-prev,
.homepage-slides:hover .owl-nav button.owl-next {
  opacity: 1;
  visibility: visible;
}

.homepage-slides .owl-nav button.owl-next {
  left: auto;
  right: 50px;
}

.social-list li {
  display: inline;
  float: left;
  margin-right: 15px;
}
.social-list li a {
  background: #303743;
  height: 42px;
  width: 42px;
  display: inline-block;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  line-height: 42px;
  font-size: 16px;
}
.social-list li a:hover {
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
}
.social-list.list-primary-color li a {
  background: #0c59db;
}
.social-list.list-flat li a {
  border-radius: 0;
}
.social-list.list-lg li a {
  height: 48px;
  width: 48px;
  font-size: 18px;
  line-height: 48px;
}
.social-list.list-sm li {
  margin-right: 10px;
  height: 34px;
  width: 34px;
  font-size: 14px;
  line-height: 34px;
}
.social-list.list-sm li a {
  height: 34px;
  width: 34px;
  font-size: 14px;
  line-height: 34px;
}

.order-list li {
  display: inherit;
  position: relative;
  padding-left: 30px;
  margin: 12px 0px;
}
.order-list li:before {
  position: absolute;
  content: '\f192';
  left: 0px;
  top: 0px;
  font-family: "Font Awesome 5 Free";
}
.order-list li:first-child {
  margin-top: 0;
}
.order-list.primary-color li:before {
  color: #0c59db;
  font-weight: 500;
}

.list-items li {
  display: inherit;
  padding: 5px 0;
}

#curve {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

#curve path {
  fill: #fff;
}

.google-map {
  height: 465px;
}

.google-map.map-full-height {
  height: 100%;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 100%;
  width: 100%;
}

.mapouter.fixed-height {
  height: 480px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: auto;
}

.mapouter iframe {
  width: 100%;
  height: 100%;
}

.back-to-top {
  position: fixed;
  right: 36px;
  bottom: 42px;
  width: 42px;
  height: 42px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  z-index: 99;
  font-size: 22px;
  cursor: pointer;
  background-color: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  -webkit-animation: white-shadow .8s infinite, red-shadow .8s infinite;
  animation: white-shadow .8s infinite, red-shadow .8s infinite;
  -webkit-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -khtml-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  display: none;
}

@keyframes white-shadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    -webkit-transition: box-shadow .3s ease-in-out;
    -webkit-transition: -webkit-box-shadow .3s ease-in-out;
    -o-transition: box-shadow .3s ease-in-out;
    transition: box-shadow .3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  }
  100% {
    -webkit-box-shadow: 0 0 0 30px transparent;
    box-shadow: 0 0 0 30px transparent;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: box-shadow .4s ease-in-out;
    -webkit-transition: -webkit-box-shadow .4s ease-in-out;
    -o-transition: box-shadow .4s ease-in-out;
    transition: box-shadow .4s ease-in-out;
    transition: box-shadow 0.4s ease-in-out, -webkit-box-shadow 0.4s ease-in-out;
  }
}
@-webkit-keyframes red-shadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 100, 188, 0.4);
    box-shadow: 0 0 0 0 rgba(31, 100, 188, 0.4);
  }
  100% {
    -webkit-box-shadow: 0 0 0 30px transparent;
    box-shadow: 0 0 0 30px transparent;
  }
}
@keyframes red-shadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 100, 188, 0.4);
    box-shadow: 0 0 0 0 rgba(31, 100, 188, 0.4);
  }
  100% {
    -webkit-box-shadow: 0 0 0 30px transparent;
    box-shadow: 0 0 0 30px transparent;
  }
}
.text-under-line {
  position: relative;
  display: inline-block;
}

.text-under-line:before {
  content: '';
  background-color: #0c59db;
  height: 2px;
  width: 4.75rem;
  top: 15px;
  right: -90px;
  position: absolute;
}

.video-popup {
  position: relative;
}

.popup-youtube {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  color: #fff;
}

.popup-youtube:hover {
  color: #fff;
}

.video-popup a i::before {
  font-size: 90px;
  background: #0c59db;
  border-radius: 50%;
  line-height: 100px;
}

.video-popup-left a {
  height: 95px;
  width: 95px;
  text-align: center;
  line-height: 95px;
  background: #fff;
  font-size: 24px;
  color: #19203f;
  position: relative;
  z-index: 10;
  display: inline-block;
  border-radius: 95px;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.video-popup-left a::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  z-index: -1;
  opacity: 0;
  -webkit-animation: pulse-border-2 1.5s linear infinite;
  -moz-animation: pulse-border-2 1.5s linear infinite;
  -o-animation: pulse-border-2 1.5s linear infinite;
  animation: pulse-border-2 1.5s linear infinite;
  border-radius: 95px;
}

.video-popup-left a::after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  z-index: -1;
  opacity: 0;
  -webkit-animation: pulse-border 1s linear infinite;
  -moz-animation: pulse-border 1s linear infinite;
  -o-animation: pulse-border 1s linear infinite;
  animation: pulse-border 1s linear infinite;
  border-radius: 95px;
}

@keyframes pulse-border {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
@keyframes pulse-border-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
.pulse-animation,
.pulse-animation:before,
.pulse-animation:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 90px;
  width: 90px;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(12, 89, 219, 0.6);
  -khtml-box-shadow: 0 0 0 0 rgba(12, 89, 219, 0.6);
  -moz-box-shadow: 0 0 0 0 rgba(12, 89, 219, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(12, 89, 219, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(12, 89, 219, 0.6);
  box-shadow: 0 0 0 0 rgba(12, 89, 219, 0.6);
  -webkit-animation: pulse-animation 3s infinite;
  -khtml-animation: pulse-animation 3s infinite;
  -moz-animation: pulse-animation 3s infinite;
  -ms-animation: pulse-animation 3s infinite;
  -o-animation: pulse-animation 3s infinite;
  animation: pulse-animation 3s infinite;
}

.pulse-animation:before {
  -webkit-animation-delay: 0.9s;
  -khtml-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.pulse-animation:after {
  -webkit-animation-delay: 0.6s;
  -khtml-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

@-webkit-keyframes pulse-animation {
  70% {
    -webkit-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    -khtml-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    -moz-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    -ms-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    -o-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    -khtml-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse-animation {
  70% {
    -webkit-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    -khtml-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    -moz-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    -ms-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    -o-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    -khtml-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
/*Owl Carousel Nav Prev*/
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  font-size: 20px;
  height: 50px;
  border-radius: 50%;
  left: 45px;
  line-height: 50px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 50px;
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -moz-transition: all 600ms ease 0ms;
  -webkit-transition: all 600ms ease 0ms;
  -ms-transition: all 600ms ease 0ms;
  -o-transition: all 600ms ease 0ms;
  transition: all 600ms ease 0ms;
  opacity: 0;
  visibility: hidden;
}

.owl-carousel:hover .owl-nav button.owl-prev,
.owl-carousel:hover .owl-nav button.owl-next {
  opacity: 1;
  visibility: visible;
}

.owl-carousel:hover .owl-nav button.owl-prev {
  left: 15px;
}

.owl-carousel:hover .owl-nav button.owl-next {
  right: 15px;
}

.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: 45px;
}

.owl-carousel .owl-nav button i {
  color: #fff;
}

.mean-container a.meanmenu-reveal {
  border: 1px solid #0c59db;
  color: #0c59db;
  margin-top: -45px;
}

.mean-container a.meanmenu-reveal span {
  background: #0c59db;
}

.pagination-nav .pagination-list > li {
  text-align: center;
  margin-right: 8px;
}
.pagination-nav .pagination-list > li:last-child {
  margin-right: 0;
}
.pagination-nav .pagination-list > li > a {
  color: #565151;
  font-weight: 500;
  height: 54px;
  width: 54px;
  line-height: 54px;
  display: inline-block;
  border: 1px solid #ddd;
  position: relative;
  border-radius: 50%;
}
.pagination-nav .pagination-list > li:hover a {
  border: 1px solid transparent;
  background: #0c59db;
  color: #fff;
}
.pagination-nav .pagination-list > li.active a {
  border: 1px solid transparent;
  background: #0c59db;
  color: #fff;
}

.skills .skill-item {
  position: relative;
  margin-bottom: 20px;
}
.skills .skill-item .skill-header {
  position: relative;
  margin-bottom: 5px;
}
.skills .skill-item .skill-header .skill-percentage {
  position: absolute;
  right: 0;
  top: 1px;
  font-size: 15px;
  font-weight: 600;
  color: #17305e;
}
.skills .skill-item:last-child {
  margin-bottom: 0;
}
.skills .skill-item .skill-bar {
  position: relative;
  width: 100%;
}
.skills .skill-item .skill-bar .bar-inner {
  position: relative;
  width: 100%;
  height: 20px;
  background: #EBEAF0;
  border-radius: 45px;
}
.skills .skill-item .skill-bar .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 20px;
  background: linear-gradient(-20deg, #0E90D5, #3032A2) !important;
  -webkit-transition: all 2000ms ease;
  transition: all 2000ms ease;
  border-radius: 45px;
}
.skills .skill-item .skill-bar .bar-inner .bar::before {
  position: absolute;
  content: '';
  right: 0;
  height: 20px;
  width: 5px;
  background: #fff;
  top: 0;
}

.percentage {
  font-size: 12px;
  float: right;
}

.single-post-navigation .navigation-links a {
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}
.single-post-navigation .navigation-links .nav-next a i {
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
  margin-left: 8px;
}
.single-post-navigation .navigation-links .nav-next a:hover i {
  margin-left: 3px;
}
.single-post-navigation .navigation-links .nav-previous a i {
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
  margin-right: 8px;
}
.single-post-navigation .navigation-links .nav-previous a:hover i {
  margin-right: 3px;
}

.error-area {
  background-size: cover;
  background-position: center center;
}

.error-inner .error-title {
  font-size: 190px;
  line-height: 240px;
}

.error-inner .error-text {
  font-size: 40px;
}

.error-inner p {
  max-width: 545px;
  margin: 20px auto 30px;
}

.pintarest-list {
  display: flex;
  flex-wrap: wrap;
}

.pintarest-list li {
  width: 80px;
  height: 80px;
  padding: 5px;
}

#scrollUp {
  right: 36px;
  bottom: 42px;
  width: 42px;
  height: 42px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  z-index: 99;
  font-size: 22px;
  cursor: pointer;
  background-color: #0c59db;
  -webkit-animation: white-shadow .8s infinite, red-shadow .8s infinite;
  animation: white-shadow .8s infinite, red-shadow .8s infinite;
  -webkit-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -khtml-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
}

.client-item {
  padding: 15px;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}

.client-item img {
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}

.fa-phone.alt {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.anim-object,
.anim-object2 {
  position: relative;
}

.anim-object:before {
  position: absolute;
  content: "";
  background-image: url(../images/objects/1.png);
  height: 260px;
  width: 260px;
  top: 10%;
  right: 10%;
  background-size: contain;
  animation-name: spinInfinit;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.fa-phone.alt {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.anim-object2:before {
  position: absolute;
  content: "";
  background-image: url(../images/objects/3.png);
  height: 325px;
  width: 325px;
  top: 35%;
  left: 1%;
  background-size: contain;
  background-repeat: no-repeat;
  animation-name: spinInfinit;
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  opacity: 0.8;
}

/*========================================*/
/*--------------- [_Header] --------------*/
/*========================================*/
.logo {
  padding-top: 1.3125rem;
}

.logo img {
  height: 52px;
}

/*Header Style One*/
.header-style-one .header-wrapper .header-navigation-area {
  background: #ECF1F8;
  transition: all 0.4s ease-out 0s;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li {
  display: inline-block;
  margin-right: 45px;
  position: relative;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li:last-child {
  margin-right: 0;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li a {
  padding: 36px 0;
  display: block;
  color: #2f2b2b;
  font-weight: 600;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li.has-sub.right-view ul {
  right: -20px;
  left: auto;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li.has-sub > a::before {
  content: "\f067";
  position: absolute;
  right: -16px;
  top: 50%;
  -moz-transform: translate(0, -13px);
  -o-transform: translate(0, -13px);
  -ms-transform: translate(0, -13px);
  -webkit-transform: translate(0, -13px);
  transform: translate(0, -13px);
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li:hover > a {
  color: #0c59db;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li:hover ul.sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
  -moz-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu {
  position: absolute;
  width: 220px;
  background: #f8f8f8;
  z-index: 2;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
  opacity: 0;
  visibility: hidden;
  left: 0;
  transform-origin: top;
  top: 100%;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li {
  display: block;
  margin: 0;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li:last-child {
  border-bottom: none;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li a {
  padding: 8px 20px;
  font-size: 14px;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li:hover > a {
  padding-left: 25px;
  background-color: #0c59db;
  color: #fff;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li:hover ul.sub-menu {
  opacity: 1;
  visibility: visible;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li ul.sub-menu {
  left: 100%;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.header-style-one .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li.has-sub-child > a::before {
  content: "\f105";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-15px);
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
}
.header-style-one .header-wrapper .header-navigation-area.inner-page .main-menu ul li a {
  padding: 35px 0;
}
.header-style-one .header-wrapper .header-navigation-area.transparent-header {
  background: transparent;
  position: fixed;
  z-index: 9999;
  width: 100%;
  padding: 20px 0;
  top: 0;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-style-one .header-wrapper .header-navigation-area.transparent-header.fixed {
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  padding: 10px 0;
  -webkit-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -khtml-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
}
.header-style-one .header-wrapper .header-navigation-area.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -khtml-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

/*Header Style Two*/
.header-style-two .header-wrapper .header-middle {
  padding: 30px 0;
  margin: 0 auto;
  position: static;
}
.header-style-two .header-wrapper .header-middle .topbar-info-area i {
  margin-right: 15px;
  color: #0c59db;
  vertical-align: middle;
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  background: #ECF1F8;
  border: 1px solid #eee;
}
.header-style-two .header-wrapper .header-middle .topbar-info-area i:before {
  font-size: 26px;
}
.header-style-two .header-wrapper .header-middle .topbar-info-area a {
  font-size: 15px;
}
.header-style-two .header-wrapper .header-navigation-area {
  background: #F7F8FC;
  position: relative;
  z-index: 5;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li {
  display: inline-block;
  margin-right: 45px;
  position: relative;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li:last-child {
  margin-right: 0;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li a {
  padding: 45px 0;
  display: block;
  color: #2f2b2b;
  font-weight: 600;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li.has-sub.right-view ul {
  right: -20px;
  left: auto;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li.has-sub > a::before {
  content: "\f067";
  position: absolute;
  right: -18px;
  top: 50%;
  -moz-transform: translate(0, -13px);
  -o-transform: translate(0, -13px);
  -ms-transform: translate(0, -13px);
  -webkit-transform: translate(0, -13px);
  transform: translate(0, -13px);
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li:hover > a {
  color: #0c59db;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li:hover ul.sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu {
  position: absolute;
  width: 220px;
  background: #ECF1F8;
  z-index: 2;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
  opacity: 0;
  visibility: hidden;
  left: 0;
  transform-origin: top;
  top: 100%;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li {
  display: block;
  margin: 0;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li:last-child {
  border-bottom: none;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li a {
  padding: 8px 20px;
  font-size: 14px;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li:hover > a {
  padding-left: 25px;
  background: #0c59db;
  color: #fff;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li:hover ul.sub-menu {
  opacity: 1;
  visibility: visible;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li ul.sub-menu {
  left: 100%;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li ul.sub-menu li.has-sub-child > a::before {
  content: "\f067";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-12px);
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
}
.header-style-two .header-wrapper .header-navigation-area.inner-page .main-menu ul li a {
  padding: 35px 0;
}
.header-style-two .header-wrapper .header-navigation-area.transparent-header {
  background: transparent;
  position: fixed;
  z-index: 9999;
  width: 100%;
  padding: 20px 0;
  top: 0;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-style-two .header-wrapper .header-navigation-area.transparent-header.fixed {
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  padding: 10px 0;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -khtml-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.header-style-two .header-wrapper .header-navigation-area.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -khtml-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -moz-transition: all 600ms ease-out 0s;
  -webkit-transition: all 600ms ease-out 0s;
  -ms-transition: all 600ms ease-out 0s;
  -o-transition: all 600ms ease-out 0s;
  transition: all 600ms ease-out 0s;
  -moz-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

/*Header Searchbox Style Two*/
.header-searchbox-style-one {
  position: relative;
  float: right;
}
.header-searchbox-style-one .show-searchbox {
  float: right;
  margin-left: 32px;
  position: relative;
  margin-top: 3px;
}
.header-searchbox-style-one .show-searchbox a {
  position: relative;
  width: 35px;
  height: 35px;
  background: #0c59db;
  display: block;
  border-radius: 6px;
  text-align: center;
  line-height: 35px;
  margin: 27px 0 30px;
}
.header-searchbox-style-one .show-searchbox a i {
  font-size: 14px;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-searchbox-style-one .show-searchbox.active .fa-search::before {
  content: "\f00d";
}
.header-searchbox-style-one .toggle-searchbox {
  position: absolute;
  right: 0;
  top: 170%;
  width: 315px;
  opacity: 0;
  padding: 20px;
  visibility: hidden;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-searchbox-style-one .toggle-searchbox #searchform-all {
  position: relative;
}
.header-searchbox-style-one .toggle-searchbox #searchform-all #s {
  margin-bottom: 0;
  height: 50px;
  font-size: 14px;
  color: #0c59db;
  top: 6px;
  background-color: #fff;
  border-radius: 0;
}
.header-searchbox-style-one .toggle-searchbox #searchform-all #searchsubmit {
  padding: 3px;
  position: absolute;
  right: 12px;
  top: 9px;
  font-family: "" Font Awesome 5 Free "";
  color: #0c59db;
  background: transparent;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-searchbox-style-one .toggle-searchbox form input[type="text"] {
  border: 1px solid #ddd6d9;
}
.header-searchbox-style-one .toggle-searchbox.show {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  top: 103%;
  padding: 20px;
  background: #f4f4f4;
  border: 1px solid #0c59db;
}

/*Header Searchbox Style Two*/
.header-searchbox-style-two {
  position: relative;
  float: right;
  margin-top: 6px;
  margin-left: 30px;
}
.header-searchbox-style-two .show-searchbox {
  float: right;
  margin-left: 32px;
  position: relative;
  margin-top: 3px;
}
.header-searchbox-style-two .show-searchbox a {
  position: relative;
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 55px;
  margin: 25px 0 30px;
}
.header-searchbox-style-two .show-searchbox a i {
  font-size: 20px;
  color: #0c59db;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-searchbox-style-two .show-searchbox.active .fa-search::before {
  content: "\f00d";
}
.header-searchbox-style-two .toggle-searchbox {
  position: absolute;
  right: 0;
  top: 170%;
  width: 315px;
  opacity: 0;
  padding: 20px;
  visibility: hidden;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-searchbox-style-two .toggle-searchbox #searchform-all {
  position: relative;
}
.header-searchbox-style-two .toggle-searchbox #searchform-all #s {
  margin-bottom: 0;
  height: 50px;
  font-size: 14px;
  color: #0c59db;
  top: 6px;
  background-color: #fff;
  border-radius: 0;
}
.header-searchbox-style-two .toggle-searchbox #searchform-all #searchsubmit {
  padding: 3px;
  position: absolute;
  right: 12px;
  top: 9px;
  font-family: "" Font Awesome 5 Free "";
  color: #0c59db;
  background: transparent;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-searchbox-style-two .toggle-searchbox form input[type="text"] {
  border: 1px solid #ddd6d9;
}
.header-searchbox-style-two .toggle-searchbox.show {
  visibility: visible;
  opacity: 1;
  z-index: 2;
  top: 114%;
  padding: 20px;
  background: #f4f4f4;
  border: 1px solid #0c59db;
}

.input-box {
  position: absolute;
  right: 12px;
  top: 12px;
}
.input-box i {
  color: #0c59db;
}

/*Header-top-area*/
.header-top-area {
  font-size: 14px;
  color: #fff;
  padding: 10px 0;
}
.header-top-area a {
  color: #fff;
  font-size: 14px;
  display: block;
  font-weight: 400;
}
.header-top-area i {
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
}
.header-top-area .header-top-left-part .address {
  padding-right: 20px;
  position: relative;
}
.header-top-area .header-top-left-part .address:after {
  background: #fff;
  content: "";
  position: absolute;
  height: 90%;
  width: 1px;
  right: 0;
  top: 2px;
}
.header-top-area .header-top-left-part .phone {
  padding-left: 20px;
}
.header-top-area .header-top-left-part i {
  color: #fff;
  margin-right: 5px;
  vertical-align: middle;
}
.header-top-area .header-top-left-part i:before {
  font-size: 16px;
}
.header-top-area .header-top-right-part .social-links {
  position: relative;
  display: inline-block;
}
.header-top-area .header-top-right-part .social-links::after {
  position: absolute;
  content: "";
  right: 0px;
  top: 7px;
  height: 60%;
  width: 1px;
  background: #fff;
}
.header-top-area .header-top-right-part .social-links li {
  display: inline-block;
  margin-right: 15px;
}
.header-top-area .header-top-right-part .social-links li a {
  color: #fff;
  -webkit-transition: .3s;
  transition: .3s;
}
.header-top-area .header-top-right-part .social-links li a:hover {
  color: #17305e;
}
.header-top-area .header-top-right-part .social-links li:last-child {
  margin-right: 25px;
}
.header-top-area .header-top-right-part .language {
  display: inline-block;
  margin-left: 25px;
  position: relative;
}
.header-top-area .header-top-right-part .language .language-btn {
  position: relative;
  text-decoration: none;
  width: 85px;
  text-align: left;
  -webkit-transition: .5s;
  transition: .5s;
}
.header-top-area .header-top-right-part .language .language-btn i {
  margin-right: 4px;
}
.header-top-area .header-top-right-part .language .language-btn i::before {
  font-size: 14px;
}
.header-top-area .header-top-right-part .language .language-btn:hover {
  color: #17305e;
}
.header-top-area .header-top-right-part .language .language-btn::after {
  content: "\f107";
  position: absolute;
  right: -3px;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  top: 0;
  font-size: 16px;
}
.header-top-area .header-top-right-part .language .language-dropdown {
  text-align: center;
  position: absolute;
  z-index: 10;
  top: 36px;
  right: -13px;
  width: 120px;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: .5s;
  transition: .5s;
}
.header-top-area .header-top-right-part .language .language-dropdown.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  background: #31314d;
}
.header-top-area .header-top-right-part .language .language-dropdown li {
  position: relative;
  background-color: #0a3041;
  z-index: -1;
}
.header-top-area .header-top-right-part .language .language-dropdown li a {
  display: block;
  position: relative;
  padding: 5px 10px;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-decoration: none;
}
.header-top-area .header-top-right-part .language .language-dropdown li a:hover {
  background: #0c59db;
}
.header-top-area .header-top-right-part .language .language-dropdown li :last-child a {
  border-bottom: none;
}

/* extra info */
.side-panel-content {
  background: #fff;
  height: 100vh;
  padding: 30px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
  width: 355px;
  z-index: 9999;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  padding-bottom: 40px;
}

.side-panel-content.side-panel-open {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
  -khtml-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
}

.close-icon {
  text-align: right;
}

.close-icon > button {
  cursor: pointer;
  background: #0c59db;
  border-radius: 50%;
  height: 42px;
  width: 42px;
}

.close-icon > button i {
  line-height: 20px;
  color: #fff;
}

.close-icon > button i:before {
  font-size: 14px;
}

.extra-info > p {
  color: #ddd;
  font-size: 15px;
  line-height: 28px;
}

.extra-info.extra-info-left.side-panel-open {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.side-panel {
  width: 36px;
  float: right;
  padding-top: 38px;
  cursor: pointer;
  margin-left: 10px;
}

.side-panel span {
  margin: 3px 0;
  transition: .3s;
  float: right;
}

.side-panel:hover span {
  width: 100%;
}

.bar1 {
  width: 55%;
  background: #0c59db;
  height: 2px;
}

.bar2 {
  width: 75%;
  background: #0c59db;
  height: 2px;
}

.bar3 {
  width: 65%;
  background: #0c59db;
  height: 2px;
}

/*========================================*/
/*-------------- [_Footer] ---------------*/
/*========================================*/
.footer-main-area {
  background: #222935;
  padding: 100px 0 60px;
}

.footer-bottom-area {
  background: #303743;
  padding: 20px 0;
}

.footer-widget {
  margin-bottom: 40px;
  display: inline-block;
}

.footer-widget-list li {
  display: inherit;
  margin-bottom: 10px;
}
.footer-widget-list li a {
  color: #a7a8b4;
  position: relative;
  padding-left: 25px;
}
.footer-widget-list li a:before {
  position: absolute;
  content: '\f105';
  left: 0px;
  top: -3px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.footer-widget-list li a:hover {
  color: #0c59db;
}

/*========================================*/
/*-------------- [_Form] -----------------*/
/*========================================*/
.form-control {
  box-shadow: none;
  outline: none;
  border: 1px solid #dfdce6;
  background: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  color: #17305e;
  height: auto;
  padding: 25px 20px;
  resize: none;
  min-width: 100%;
  height: 65px;
}

textarea.form-control {
  min-height: 210px;
}

.form-control:focus {
  border-color: #e41f7a;
}

.contact-form textarea {
  display: inline-block;
  min-height: 210px;
}

.contact-form textarea,
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="password"] {
  background: #EDF0FA;
  border: 1px solid transparent;
}

.contact-form .form-control:focus {
  border-color: #0c59db;
  background: #EDF0FA;
}

.request-a-call-back-form {
  background: #fff;
  padding: 55px 50px 60px 50px;
  -webkit-box-shadow: -5px -1px 15px 10px rgba(38, 37, 37, 0.1);
  -khtml-box-shadow: -5px -1px 15px 10px rgba(38, 37, 37, 0.1);
  -moz-box-shadow: -5px -1px 15px 10px rgba(38, 37, 37, 0.1);
  -ms-box-shadow: -5px -1px 15px 10px rgba(38, 37, 37, 0.1);
  -o-box-shadow: -5px -1px 15px 10px rgba(38, 37, 37, 0.1);
  box-shadow: -5px -1px 15px 10px rgba(38, 37, 37, 0.1);
  margin-top: -100px;
  margin-left: -160px;
  position: relative;
}

.request-a-call-back-form:after {
  border-color: #0c59db transparent transparent #3032A2;
  content: "";
  position: absolute;
  border-width: 12px 12px 12px 12px;
  height: 64px;
  width: 64px;
  border-style: solid;
  top: 0;
  opacity: 0.75;
  left: 0;
}

.request-a-call-back-form:before {
  position: absolute;
  content: "";
  border-width: 12px 12px 12px 12px;
  border-color: #0c59db #3032A2 transparent transparent;
  top: 0;
  right: 0;
  height: 64px;
  width: 64px;
  border-style: solid;
  opacity: 0.75;
}

.request-a-call-back-form.form-control {
  margin-bottom: 20px;
}

.request-a-call-back-form .custom-select-categories {
  background-color: #fff;
  color: #17305e;
  height: 56px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  padding: 4px 15px;
  border: 1px solid #dfdce6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: none;
}

.request-a-call-back-form.sidebar {
  background: #17305e;
  padding: 30px;
}

select {
  width: 268px;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 34px;
  background: url(../images/down-arrow.png) no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: 96%;
}

.contact-block {
  background: #EDF0FA;
  padding: 30px 30px;
}

.contact-icon i {
  width: 84px;
  height: 84px;
  background: #0c59db;
  text-align: center;
  line-height: 84px;
  display: inline-block;
  border-radius: 50%;
}

.contact-icon i:before {
  color: #fff;
  font-size: 36px;
}

.contact-details {
  margin-left: 30px;
}

/*========================================*/
/*---------------- [_widget] -------------*/
/*========================================*/
/*Widget Search*/
.sidebar-widget {
  padding: 30px;
  margin-bottom: 30px;
  background: #edf0fa;
  border-radius: 8px;
}

.widget-title {
  position: relative;
}
.widget-title:after {
  position: absolute;
  content: "";
  height: 4px;
  width: 58px;
  left: 0;
  bottom: -15px;
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  border-radius: 6px;
}

.single-blog-widget-title {
  background: linear-gradient(-20deg, #0E90D5, #3032A2) !important;
  color: #fff;
  padding: 8px 24px;
}

.news-sidebar-widget .widget-search .search-form {
  position: relative;
}
.news-sidebar-widget .widget-search .search-form label {
  display: block;
  margin-bottom: 0;
}
.news-sidebar-widget .widget-search .search-form input {
  height: 50px;
  padding: 6px 15px;
  border: 1px solid #eeeeee;
  width: 100%;
  display: block;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.news-sidebar-widget .widget-search .search-form button {
  position: absolute;
  right: 0;
  outline: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  z-index: 1;
  border: none;
  color: #ffffff;
  background-color: #0c59db;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}
.news-sidebar-widget .widget-popular-posts .post-date {
  font-size: 14px;
}
.news-sidebar-widget .widget-categories ul li, .news-sidebar-widget .widget-archives ul li {
  display: inherit;
  padding: 5px 0;
}
.news-sidebar-widget .widget-categories ul li a, .news-sidebar-widget .widget-archives ul li a {
  color: #565151;
}
.news-sidebar-widget .widget-categories ul li a:hover, .news-sidebar-widget .widget-archives ul li a:hover {
  color: #0c59db;
}
.news-sidebar-widget .widget-categories ul li:first-child, .news-sidebar-widget .widget-archives ul li:first-child {
  padding-top: 0;
}
.news-sidebar-widget .widget-tags ul li a {
  background: #fafcfe;
  color: #565151;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 14px;
  padding: 8px 16px 8px 16px;
  border-radius: 6px;
  display: inline-block;
}
.news-sidebar-widget .widget-tags ul li a:hover {
  color: #fff;
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
}

.news-sidebar-widget .widget-categories ul li:last-child,
.news-sidebar-widget .widget-archives ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.single-post {
  border-bottom: 1px solid #dbdde1;
  padding-bottom: 20px;
}

.single-post:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.single-post .post-image img {
  border-radius: 50%;
}

/*========================================*/
/*------------- [_Home_Slider] -----------*/
/*========================================*/
.home-carousel .slide-item {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 190px 0px 290px 0px;
}

.home-carousel .slide-item .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.banner-section {
  position: relative;
}

.home-carousel {
  position: relative;
}

.home-carousel .slide-item {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 200px 0px 200px 0px;
}

.home-carousel .slide-item:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(-180deg, rgba(22, 3, 1, 0), #09334f 150%);
  top: 0px;
  right: 0px;
  z-index: 1;
}

.home-carousel .slide-item .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-carousel .content-box {
  position: relative;
  display: block;
  z-index: 5;
  width: 100%;
}

.home-carousel .content-box h1 {
  position: relative;
  display: block;
  color: #fff;
  font-size: 56px;
  line-height: 62px;
  font-weight: 900;
  margin-bottom: 30px;
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.home-carousel .active .content-box h1 {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  -ms-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
}

.home-carousel .content-box p {
  position: relative;
  display: block;
  color: #fff;
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 36px;
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 1300ms ease;
  -moz-transition: all 1300ms ease;
  -ms-transition: all 1300ms ease;
  -o-transition: all 1300ms ease;
  transition: all 1300ms ease;
}

.home-carousel .active .content-box p {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1300ms;
  -moz-transition-delay: 1300ms;
  -ms-transition-delay: 1300ms;
  -o-transition-delay: 1300ms;
  transition-delay: 1300ms;
}

.banner-section .home-carousel .content-box a.theme-btn {
  padding: 14px 47px;
}

.home-carousel .content-box .btn-box {
  position: relative;
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 1600ms ease;
  -moz-transition: all 1600ms ease;
  -ms-transition: all 1600ms ease;
  -o-transition: all 1600ms ease;
  transition: all 1600ms ease;
}

.home-carousel .active .content-box .btn-box {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1600ms;
  -moz-transition-delay: 1600ms;
  -ms-transition-delay: 1600ms;
  -o-transition-delay: 1600ms;
  transition-delay: 1600ms;
}

/*========================================*/
/*----------- [_Section_Title] -----------*/
/*========================================*/
.section-title {
  margin-bottom: 60px;
}
.section-title .sub-title {
  margin-bottom: 5px;
  font-size: 16px;
}

.section-title-left-part {
  position: relative;
  padding-left: 30px;
}

.section-title-left-part:before {
  content: "";
  position: absolute;
  background: #0c59db;
  height: 100%;
  width: 8px;
  top: 0;
  left: 0;
}

.section-title-left-part:after {
  content: "\f180";
  font-family: Flaticon;
  position: absolute;
  top: 17px;
  left: 45px;
  font-size: 7rem;
  color: #1677bf;
  opacity: 0.1;
  animation: heartbeat 1s infinite alternate;
}

.title-box-center {
  position: relative;
}

.shadow-text {
  position: relative;
  font-size: 152px;
  opacity: 0.05;
  left: 0;
  right: 0;
  top: 15px;
  color: #3751ca;
  font-weight: 800;
  animation: heartbeat 1s infinite alternate;
}

/*========================================*/
/*------------- [_Title_Lines] -----------*/
/*========================================*/
.title-under-line {
  position: relative;
}

.title-under-line:before {
  content: "";
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  position: absolute;
  height: 10px;
  width: 72px;
  bottom: -35px;
  left: 0;
}

.title-under-line:after {
  background: #ffffff;
  position: absolute;
  content: "";
  height: 2px;
  width: 30px;
  bottom: -31px;
  left: 0px;
}

.line-both-side {
  position: relative;
  display: inline-block;
}

.line-both-side:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 45px;
  top: 12px;
  right: -55px;
  background: #fff;
}

.line-both-side:after {
  position: absolute;
  background: #fff;
  height: 2px;
  width: 45px;
  content: "";
  left: -55px;
  top: 12px;
}

.line-both-side.gradient-color:before {
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
}

.line-both-side.gradient-color:after {
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
}

.sub-title-side-line {
  position: relative;
  padding-left: 60px;
  display: inline-block;
}

.sub-title-side-line:before {
  position: absolute;
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  height: 2px;
  width: 50px;
  content: "";
  top: 15px;
  left: 0;
}

.line-top-center {
  position: relative;
}

.line-top-center:before {
  content: "";
  position: absolute;
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  height: 3px;
  width: 36px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -15px;
}

.side-line {
  position: relative;
}

.side-line:before {
  position: absolute;
  content: "";
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  height: 3px;
  width: 40px;
  top: 12px;
  left: -15px;
  z-index: -1;
  -moz-transition: all 500ms ease-out 0s;
  -webkit-transition: all 500ms ease-out 0s;
  -ms-transition: all 500ms ease-out 0s;
  -o-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
}

.case-study-item:hover .side-line:before {
  width: 65px;
  left: -30px;
}

/*========================================*/
/*-------------- [_Page_Title] -----------*/
/*========================================*/
.page-title-section {
  background-color: #f8f8f8;
  /* background-image: url(); */
  color: #fff;
}

.page-title-content {
  display: flex;
  align-items: center;
  position: relative;
  height: 320px;
}

.page-title-content .title {
  font-size: 54px;
  letter-spacing: 1px;
}

.page-title-content nav {
  position: absolute;
  left: 0;
  bottom: 0;
}

.page-title-content nav ol {
  margin: 0;
  border-radius: 0;
  background: rgba(11, 18, 30, 0.6);
}

.page-title-content .breadcrumb-item a {
  color: #FDE428;
}

.page-title-content .breadcrumb-item.active {
  color: #ffffff;
}

.page-title-content .breadcrumb-item + .breadcrumb-item::before {
  content: "|";
}

/*========================================*/
/*---------- [_Section_About] ------------*/
/*========================================*/
.about-image-block {
  position: relative;
}

.about-image-block-2 {
  position: relative;
  padding-right: 45px;
}

.about-image-block-2 img {
  border-radius: 10px;
}

.about-image-block-3 {
  position: relative;
  margin-bottom: -145px;
}

.signature img {
  width: 125px;
}

.about-image-block:after {
  position: absolute;
  content: "";
  height: 270px;
  width: 255px;
  background: #8090c7;
  z-index: -1;
  right: 90px;
  margin: 0 auto;
  top: 45px;
  opacity: 0.15;
  animation: heartbeat 1s infinite alternate;
}

.feature-box {
  text-align: center;
}

.feature-thumb {
  overflow: hidden;
}

.feature-thumb img {
  -moz-border-radius: 10px 10px 0 0px;
  -webkit-border-radius: 10px 10px 0 0px;
  -ms-border-radius: 10px 10px 0 0px;
  -o-border-radius: 10px 10px 0 0px;
  border-radius: 10px 10px 0 0px;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
}

.feature-box:hover .feature-thumb img {
  -moz-transform: scale(1.2) rotate(3deg);
  -o-transform: scale(1.2) rotate(3deg);
  -ms-transform: scale(1.2) rotate(3deg);
  -webkit-transform: scale(1.2) rotate(3deg);
  transform: scale(1.2) rotate(3deg);
}

.feature-content {
  padding: 30px 25px 45px;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  position: relative;
  -moz-transition: all 400ms ease-out 0s;
  -webkit-transition: all 400ms ease-out 0s;
  -ms-transition: all 400ms ease-out 0s;
  -o-transition: all 400ms ease-out 0s;
  transition: all 400ms ease-out 0s;
}

.feature-box:hover .feature-content {
  -webkit-box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.06);
  -khtml-box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.06);
  -ms-box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.06);
  -o-box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.06);
}

.feature-content:after {
  border-radius: 15px;
  position: absolute;
  background: #0c59db;
  height: 3px;
  width: 0;
  left: 0;
  bottom: 0;
  content: "";
  right: 0;
  margin: 0 auto;
  -moz-transition: all 400ms ease-out 0s;
  -webkit-transition: all 400ms ease-out 0s;
  -ms-transition: all 400ms ease-out 0s;
  -o-transition: all 400ms ease-out 0s;
  transition: all 400ms ease-out 0s;
  opacity: 0.6;
  z-index: 0;
}

.feature-box:hover .feature-content:after {
  width: 85%;
}

.feature-content .title {
  margin-bottom: 15px;
}

.feature-box .link a {
  position: absolute;
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  color: #fff;
  height: 45px;
  width: 85px;
  line-height: 45px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -20px;
  border-radius: 30px;
  z-index: 1;
  -moz-transition: all 400ms ease-out 0s;
  -webkit-transition: all 400ms ease-out 0s;
  -ms-transition: all 400ms ease-out 0s;
  -o-transition: all 400ms ease-out 0s;
  transition: all 400ms ease-out 0s;
}

.feature-box .link a:hover {
  width: 110px;
}

/*========================================*/
/*---------- [_Section_Service] ----------*/
/*========================================*/
.service-section {
  position: relative;
}

/*========================================*/
/*------ [_Section_Single_Service] -------*/
/*========================================*/
.service-description {
  margin-left: 30px;
}

.service-description .title {
  margin-bottom: 10px;
}

.service-link-list li a {
  display: block;
  background: #edf0fa;
  color: #40436a;
  padding: 24px 40px;
  font-size: 16px;
  font-weight: normal;
  font-weight: 600;
  margin-bottom: 10px;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.service-link-list li a:after,
.service-link-list li.active a:after {
  position: absolute;
  content: "";
  height: 100%;
  background: #2a65c9;
  left: -7px;
  width: 22px;
  top: 0;
  opacity: 0;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
  -moz-transform: skewX(6deg);
  -o-transform: skewX(6deg);
  -ms-transform: skewX(6deg);
  -webkit-transform: skewX(6deg);
  transform: skewX(6deg);
}

.service-link-list li a:hover:after,
.service-link-list li.active a:after {
  opacity: 1;
}

.service-link-list li.active a {
  color: #ffffff;
  padding-left: 50px;
  background: linear-gradient(-20deg, #0E90D5, #3032A2) !important;
}

.service-link-list li a:hover {
  background: linear-gradient(-20deg, #0E90D5, #3032A2) !important;
  color: #fff;
  padding-left: 50px;
}

.service-link-list li a i {
  margin-right: 10px;
  font-size: 13px;
}

.service-link-list li.active a i {
  margin-right: 12px;
}

.service-link-list li a:hover i {
  margin-right: 12px;
}

.service-section-title-area {
  background: #571bcd;
  padding: 90px 0 240px;
  margin: 0 110px;
  margin-top: -180px;
}

.service-section-content {
  margin-top: -180px;
}

.service-features-icon {
  margin-right: 25px;
}

.service-features-icon span {
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  padding: 15px;
  display: inline-block;
  border-radius: 8px;
}

.service-features-icon span:before {
  font-size: 42px;
  line-height: 42px;
  color: #fff;
}

/*========================================*/
/*---------- [_Section_Project] ----------*/
/*========================================*/
.project-section .project-filter ul li {
  display: inline-block;
  border: 1px solid #d6d6df;
  padding: 8px 25px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #373959;
  font-weight: 500;
  border-radius: 25px;
  margin-right: 5px;
}
.project-section .project-filter ul li:hover, .project-section .project-filter ul li.active {
  background: #0c59db;
  color: #fff;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.project-item .project-overlay {
  left: 0;
  bottom: 0;
  padding: 20px 10px 20px 20px;
  z-index: 1;
  width: 100%;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #0c59db;
}
.project-item .project-overlay i:before {
  font-size: 38px;
  font-weight: 100;
  margin-right: 15px;
  vertical-align: middle;
}
.project-item .project-overlay a:hover {
  color: #fff;
}
.project-item .link-single-page a {
  position: absolute;
  z-index: 2;
  top: -15px;
  right: 15px;
  width: 48px;
  height: 48px;
  background: #0c59db;
  text-align: center;
  line-height: 58px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.project-item:hover .link-single-page a {
  visibility: visible;
  opacity: 1;
  top: 15px;
}
.project-item:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.project-item img {
  width: 100%;
  -webkit-transition: 1s;
  transition: 1s;
}

.project-item .link-single-page a {
  position: absolute;
  z-index: 2;
  top: 30%;
  left: 50%;
  width: 48px;
  margin-top: -25px;
  margin-left: -25px;
  height: 48px;
  background: #0c59db;
  text-align: center;
  line-height: 58px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.project-item:hover .link-single-page a {
  visibility: visible;
  opacity: 1;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.project-item .project-overlay i:before {
  font-size: 38px;
  font-weight: 100;
  margin-right: 15px;
  vertical-align: middle;
}

.project-thumb {
  position: relative;
  overflow: hidden;
}

.project-thumb:after {
  content: "";
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scaleY(0);
  transition: 500ms;
}

.project-item:hover .project-thumb:after {
  transform: scale(1);
}

.project-item:hover .link-single-page a {
  visibility: visible;
  opacity: 1;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.project-item .project-overlay i.arrow:before {
  font-size: 22px;
  margin-left: 10px;
}

.project-item .project-overlay i.arrow:before {
  font-size: 22px;
  margin-left: 10px;
  transition: 300ms;
}

.project-item .project-overlay:hover i.arrow:before {
  margin-left: 20px;
}

.project-item .project-overlay .project-title {
  font-size: 20px;
}

.section-icon:before {
  content: "\f180";
  font-family: Flaticon;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 85px;
  opacity: 0.2;
  color: #baa185;
  animation: infiUpDown 1s infinite alternate;
}

/*=================================================*/
/*---------- [_Section_Single_Project] ------------*/
/*=================================================*/
.project-info-list li {
  padding: 8px 0;
  color: #677286;
  display: inherit;
}
.project-info-list li span {
  color: #233d63;
  font-weight: 600;
  margin-right: 5px;
}
.project-info-list li span i {
  color: #0c59db;
  font-size: 18px;
  margin-right: 10px;
}
.project-info-list li a {
  color: #677286;
}

/*========================================*/
/*---------- [_Section_Funfact] ----------*/
/*========================================*/
.funfact {
  background: #F7F8FC;
  padding: 40px 40px 40px;
  position: relative;
}
.funfact .icon {
  margin-bottom: 30px;
  position: absolute;
  top: 45px;
  right: 15px;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.funfact .icon span:before {
  font-size: 54px;
  color: #0c59db;
}
.funfact .counter {
  font-size: 48px;
  margin-bottom: 20px;
  z-index: 1;
  position: relative;
}
.funfact .title {
  color: #747b87;
  position: relative;
  z-index: 2;
}
.funfact::before {
  content: "";
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.05;
  -moz-transform: translate(10px, 10px);
  -o-transform: translate(10px, 10px);
  -ms-transform: translate(10px, 10px);
  -webkit-transform: translate(10px, 10px);
  transform: translate(10px, 10px);
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.funfact:hover .icon {
  top: 55px;
}
.funfact:hover::before {
  left: 5px;
  top: 6px;
  opacity: 0.08;
}

.funfact-normal .icon span:before {
  color: #fff;
  font-size: 48px;
}

.funfact-normal .counter {
  color: #0c59db;
  font-size: 56px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.funfact-normal .title {
  color: #fff;
}

/*========================================*/
/*---------- [_Section_Icon_Box] ---------*/
/*========================================*/
.icon-box-block {
  background-color: #ffffff;
  border-radius: 12px;
  text-align: center;
  border: 1px solid #f0f0f0;
  padding: 30px 25px 30px 25px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  -khtml-box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
}
.icon-box-block:after {
  /* background: url(); */
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: -35px;
  background-repeat: no-repeat;
  left: 0;
  z-index: -1;
  content: "";
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}
.icon-box-block img {
  -moz-transition: all 400ms ease-out 0s;
  -webkit-transition: all 400ms ease-out 0s;
  -ms-transition: all 400ms ease-out 0s;
  -o-transition: all 400ms ease-out 0s;
  transition: all 400ms ease-out 0s;
}
.icon-box-block:hover img {
  -moz-transform: scale(-1) rotate(180deg);
  -o-transform: scale(-1) rotate(180deg);
  -ms-transform: scale(-1) rotate(180deg);
  -webkit-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}
.icon-box-block:hover:after {
  -moz-transform: scale(1.08) translate(0px, -8px);
  -o-transform: scale(1.08) translate(0px, -8px);
  -ms-transform: scale(1.08) translate(0px, -8px);
  -webkit-transform: scale(1.08) translate(0px, -8px);
  transform: scale(1.08) translate(0px, -8px);
}

.icon-box-two .icon {
  background: #0c59db;
  border-radius: 6px;
  width: 84px;
  height: 84px;
  line-height: 84px;
  float: left;
  text-align: center;
}
.icon-box-two .icon span:before {
  font-size: 48px;
  color: #fff;
}
.icon-box-two .icon-details {
  margin-left: 110px;
}

/*========================================*/
/*------------ [_Section_News] -----------*/
/*========================================*/
.news-wrapper .news-thumb {
  position: relative;
  overflow: hidden;
}
.news-wrapper .news-thumb img {
  -moz-transition: all 500ms ease-out 0s;
  -webkit-transition: all 500ms ease-out 0s;
  -ms-transition: all 500ms ease-out 0s;
  -o-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
}
.news-wrapper .news-thumb .news-top-meta {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1;
  padding: 4px 14px;
  background: #0c59db;
  font-size: 14px;
  color: #fff;
  -moz-transition: all 400ms ease-out 0s;
  -webkit-transition: all 400ms ease-out 0s;
  -ms-transition: all 400ms ease-out 0s;
  -o-transition: all 400ms ease-out 0s;
  transition: all 400ms ease-out 0s;
}
.news-wrapper .news-thumb .news-top-meta a {
  color: #fff;
}
.news-wrapper .news-thumb .news-top-meta i {
  font-size: 15px;
  margin-right: 8px;
  vertical-align: middle;
  color: #ffffff;
}
.news-wrapper .news-thumb .news-top-meta .admin {
  background: #ececec;
  padding: 2px 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.2px;
  font-size: 11px;
  color: #17305e;
}
.news-wrapper .news-thumb .news-top-meta .admin a {
  color: #17305e;
}
.news-wrapper .news-thumb .news-top-meta .comment {
  background: #0c59db;
  padding: 2px 17.9px;
  min-width: 96px;
}
.news-wrapper .news-thumb:after {
  position: absolute;
  content: "";
  background: rgba(23, 48, 94, 0.6);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  -moz-transition: all 400ms ease-out 0s;
  -webkit-transition: all 400ms ease-out 0s;
  -ms-transition: all 400ms ease-out 0s;
  -o-transition: all 400ms ease-out 0s;
  transition: all 400ms ease-out 0s;
  -moz-transform: perspective(3em) rotateY(-3deg);
  -o-transform: perspective(3em) rotateY(-3deg);
  -ms-transform: perspective(3em) rotateY(-3deg);
  -webkit-transform: perspective(3em) rotateY(-3deg);
  transform: perspective(3em) rotateY(-3deg);
  transform-origin: top;
}
.news-wrapper .news-details {
  background: #ffffff;
  padding: 30px;
  box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.06);
}
.news-wrapper .news-details .news-description .news-bottom-meta {
  color: #828c99;
  background: #f5f6fc;
  padding: 10px 20px;
  border-left: 3px solid #0c59db;
}
.news-wrapper .news-details .news-description .the-content {
  margin-bottom: 20px;
}
.news-wrapper .news-details .news-link {
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  letter-spacing: 1.2px;
  line-height: 16px;
  position: relative;
}
.news-wrapper .news-details .news-link a {
  color: #0c59db;
  font-size: 15px;
}
.news-wrapper .news-details .news-link a i::before {
  font-size: 16px;
  vertical-align: middle;
  transition: 300ms;
}
.news-wrapper .news-details .news-link:hover {
  color: #0c59db;
}
.news-wrapper .news-details .news-link:hover:before {
  width: 100%;
}
.news-wrapper .news-details .news-link:hover a i::before {
  margin-left: 6px;
}
.news-wrapper .news-details .news-link:before {
  content: '';
  width: 60%;
  height: 2px;
  background: #0c59db;
  left: 0;
  position: absolute;
  bottom: -5px;
  -webkit-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}
.news-wrapper:hover .news-thumb img {
  -moz-transform: scale3d(1.1, 1.1, 1.1);
  -o-transform: scale3d(1.1, 1.1, 1.1);
  -ms-transform: scale3d(1.1, 1.1, 1.1);
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}
.news-wrapper:hover .news-thumb .news-top-meta {
  left: 30px;
}
.news-wrapper:hover .news-thumb:after {
  opacity: 1;
  -moz-transform: perspective(0) rotateX(0);
  -o-transform: perspective(0) rotateX(0);
  -ms-transform: perspective(0) rotateX(0);
  -webkit-transform: perspective(0) rotateX(0);
  transform: perspective(0) rotateX(0);
}

.news-wrapper .news-details .news-description .news-bottom-meta {
  color: #828c99;
  background: #f5f6fc;
  padding: 10px 20px;
  border-left: 3px solid #0c59db;
}

/*========================================*/
/*------------ [_Section_News] -----------*/
/*========================================*/
.blog-single-news .single-news-details .single-news-content .entry-meta {
  font-size: 15px;
  color: #888;
  font-weight: 500;
  margin: 25px 0px 10px 0px;
}
.blog-single-news .single-news-details .single-news-content .entry-meta i {
  font-size: 16px;
  margin-right: 5px;
  vertical-align: middle;
  color: #0c59db;
}
.blog-single-news .single-news-details .single-news-content .entry-meta .admin {
  background: #f6e1eb;
  padding: 4px 8px 6px 8px;
  color: #0c59db;
  border-radius: 3px;
  font-size: 13px;
}
.blog-single-news .single-news-details .single-news-content .single-news-tag-social-area {
  margin: 45px 0 30px;
  border-top: 1px solid #eee;
  padding: 30px 0;
  border-bottom: 1px solid #eeee;
}
.blog-single-news .single-news-details .single-news-content .single-news-tag-social-area .single-news-tags ul li a {
  padding: 10px 10px;
  background: #EDF0FA;
  font-size: 13px;
  color: #333;
  border-radius: 5px;
  margin-right: 5px;
}
.blog-single-news .single-news-details .single-news-content .single-news-tag-social-area .single-news-tags ul li a:hover {
  color: #fff;
  background-color: #0c59db;
}
.blog-single-news .single-news-details .single-news-content .single-news-tag-social-area .single-news-share .social-icons li {
  display: inline-block;
}
.blog-single-news .single-news-details .single-news-content .single-news-tag-social-area .single-news-share .social-icons li a {
  display: block;
  color: #0c59db;
  text-align: center;
  font-size: 18px;
  margin-left: 15px;
}
.blog-single-news .single-news-details .single-news-content .single-news-tag-social-area .single-news-share .social-icons li a:hover {
  color: #0c59db;
}
.blog-single-news .single-news-details .single-news-content .comments-area {
  background: #F4F5F8;
  padding: 30px 30px;
  margin-top: 30px;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .children {
  margin-left: 45px;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .comment-body {
  padding-top: 30px;
  display: inline-block;
  border-bottom: 1px solid #e6e6e6;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .comment-body .comment-author-thumb {
  margin-top: 5px;
  margin-right: 28px;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .comment-body .comment-author-thumb img {
  border-radius: 50%;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .comment-body .comment-content {
  margin-left: 95px;
  position: relative;
  overflow: hidden;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .comment-body .comment-content .comment-author {
  margin-bottom: 5px;
  font-size: 18px;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .comment-body .comment-content .comment-text {
  margin-bottom: 35px;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .comment-body .comment-content .comment-meta .comment-metadata {
  margin-bottom: 10px;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .comment-body .comment-content .comment-meta .comment-metadata span {
  font-size: 14px;
  color: #8b8b8b;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .comment-body .comment-content .reply {
  position: absolute;
  top: 10px;
  right: 0;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .comment-body .comment-content .reply .comment-reply-link {
  padding: 8px 24px;
  font-size: 13px;
  color: #fff;
  border-radius: 8px;
  background: #0c59db;
  border: 1px solid transparent;
  -moz-transition: all 200ms ease-out 0s;
  -webkit-transition: all 200ms ease-out 0s;
  -ms-transition: all 200ms ease-out 0s;
  -o-transition: all 200ms ease-out 0s;
  transition: all 200ms ease-out 0s;
}
.blog-single-news .single-news-details .single-news-content .comments-area .comment-list .comment .comment-body .comment-content .reply .comment-reply-link:hover {
  background: #3032A2;
}

/*========================================*/
/*---------- [_Section_Team] -------------*/
/*========================================*/
.team-block {
  position: relative;
  background: #f6f6f6;
  overflow: hidden;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  -khtml-box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  -moz-transition: all 700ms ease-out 0s;
  -webkit-transition: all 700ms ease-out 0s;
  -ms-transition: all 700ms ease-out 0s;
  -o-transition: all 700ms ease-out 0s;
  transition: all 700ms ease-out 0s;
}
.team-block .team-upper-part img {
  border-radius: 8px 0px 0 40px;
  -moz-transition: all 500ms ease-out 0s;
  -webkit-transition: all 500ms ease-out 0s;
  -ms-transition: all 500ms ease-out 0s;
  -o-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
}
.team-block .team-upper-part .social-list {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  -moz-transition: all 500ms ease-out 0s;
  -webkit-transition: all 500ms ease-out 0s;
  -ms-transition: all 500ms ease-out 0s;
  -o-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
  opacity: 0;
  transform: scale(0);
}
.team-block .team-bottom-part {
  padding: 20px;
  display: inline-block;
  width: 100%;
  text-align: center;
  z-index: 2;
}
.team-block .team-bottom-part .designation {
  color: #0c59db;
  -moz-transition: all 400ms ease-out 0s;
  -webkit-transition: all 400ms ease-out 0s;
  -ms-transition: all 400ms ease-out 0s;
  -o-transition: all 400ms ease-out 0s;
  transition: all 400ms ease-out 0s;
}
.team-block .team-bottom-part .social-list {
  display: inline-block;
  margin-top: 15px;
  text-align: center;
}
.team-block .team-bottom-part .social-list li a {
  background: transparent;
  font-size: 18px;
  color: #333;
}
.team-block .team-bottom-part .social-list li:last-child {
  margin-right: 0;
}
.team-block:after {
  position: absolute;
  content: "";
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  height: 8%;
  width: 12px;
  bottom: 0;
  right: 0;
  transition: 500ms;
  opacity: 0.6;
}
.team-block:before {
  content: "";
  position: absolute;
  height: 70px;
  width: 80px;
  background: linear-gradient(-20deg, #0E90D5, #3032A2) !important;
  bottom: -30px;
  left: -50px;
  transform: rotate(45deg);
  transition: 500ms;
  opacity: 0.6;
}
.team-block:hover {
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
}
.team-block:hover:after {
  content: none;
}
.team-block:hover:before {
  transform: rotate(135deg);
  opacity: 0;
}
.team-block:hover .team-upper-part img {
  margin-left: 10px;
}
.team-block:hover .team-bottom-part .social-list li a {
  color: #fff;
}
.team-block:hover .team-bottom-part .team-title a {
  color: #fff;
}
.team-block:hover .team-bottom-part .designation {
  color: #fff;
}

/*========================================*/
/*-------- [_Section_testimonials] -------*/
/*========================================*/
.testimonial-item {
  background: #f8f8fa;
  padding: 35px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  text-align: center;
}

.testimonial-thumb {
  display: inline-block;
  padding: 3px;
  border-radius: 50%;
  margin-bottom: 5px;
  background: #c1cad5;
}

.testimonial-thumb img {
  border-radius: 50%;
}

.testimonial-content .comments {
  font-style: italic;
}

.client-designation {
  font-size: 16px;
  color: #a6a4a4;
  letter-spacing: 0px;
  font-weight: 400;
  margin-bottom: 20px;
}

.star-rating li i {
  font-size: 18px;
}

.client-name {
  margin-bottom: 0px;
}

.quote-icon:before {
  position: absolute;
  right: 40px;
  text-align: center;
  z-index: 1;
  font-size: 3.2rem;
  -moz-transform: translateY(50%) rotate(-180deg);
  -o-transform: translateY(50%) rotate(-180deg);
  -ms-transform: translateY(50%) rotate(-180deg);
  -webkit-transform: translateY(50%) rotate(-180deg);
  transform: translateY(50%) rotate(-180deg);
  color: #0c59db;
  top: 10px;
}

/*=================================================*/
/*----------- [_Section_Pricing_Tables] -----------*/
/*=================================================*/
.price-table {
  position: relative;
  display: block;
  background: #F4F5F8;
  padding-bottom: 45px;
  border-radius: 8px;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}
.price-table .table-header {
  position: relative;
  display: block;
}
.price-table .table-header .pricing-plan-name {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 40px;
  color: #ffffff;
  font-weight: 600;
  padding: 15px 0;
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  border-radius: 8px 8px 0 0;
}
.price-table .table-header .price {
  font-size: 64px;
  line-height: 64px;
  color: #0B2239;
  font-weight: 600;
  background: #fff;
  padding: 45px 0 20px;
  border: 1px solid #edf1f9;
}
.price-table .table-header .price .price-currency {
  font-size: 36px;
  margin-right: 10px;
  vertical-align: text-bottom;
  font-weight: 600;
}
.price-table .table-header .price .price-duration {
  font-size: 15px;
  letter-spacing: .3px;
  font-weight: 500;
}
.price-table .table-content {
  position: relative;
  display: block;
  padding: 45px;
}
.price-table .table-content .list-items li {
  padding: 8px 0;
}
.price-table.active {
  -webkit-box-shadow: 0 10px 26px rgba(0, 0, 0, 0.2);
  -khtml-box-shadow: 0 10px 26px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 10px 26px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 10px 26px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 10px 26px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.2);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.price-table .recommended {
  position: absolute;
  top: -20px;
  right: 0;
  left: 0;
  padding: 3px;
  background: #b62b7d;
  margin: 0 auto;
  width: 40%;
  border-radius: 30px;
  color: #fff;
  font-size: 13px;
}

/*========================================*/
/*--------- [_Section_Case_Study] --------*/
/*========================================*/
.case-study-item .case-study-thumb {
  position: relative;
  overflow: hidden;
}
.case-study-item .case-study-thumb img {
  width: 100%;
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  transition: all 900ms ease;
}
.case-study-item .case-study-thumb .case-study-link-icon a {
  position: absolute;
  z-index: 5;
  bottom: 40px;
  right: -34px;
  width: 52px;
  margin-top: -25px;
  margin-left: -25px;
  height: 52px;
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  text-align: center;
  line-height: 60px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  -moz-transition: all 500ms ease-out 0s;
  -webkit-transition: all 500ms ease-out 0s;
  -ms-transition: all 500ms ease-out 0s;
  -o-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
}
.case-study-item .case-study-thumb .case-study-details {
  position: absolute;
  width: 88%;
  margin: 0 auto;
  left: 20px;
  bottom: 10px;
  -moz-transition: all 500ms ease-out 0s;
  -webkit-transition: all 500ms ease-out 0s;
  -ms-transition: all 500ms ease-out 0s;
  -o-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
  opacity: 1;
  transform-origin: bottom;
  z-index: 2;
  opacity: 0;
}
.case-study-item .case-study-thumb .case-study-details .case-study-category,
.case-study-item .case-study-thumb .case-study-details .case-study-title {
  color: #fff;
}
.case-study-item .case-study-thumb:after {
  position: absolute;
  background: linear-gradient(0deg, #11112e, transparent);
  height: 100%;
  width: 100%;
  content: "";
  left: 0;
  bottom: 0;
  opacity: 0;
  -moz-transition: all 500ms ease-out 0s;
  -webkit-transition: all 500ms ease-out 0s;
  -ms-transition: all 500ms ease-out 0s;
  -o-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
}
.case-study-item:hover .case-study-thumb img {
  -moz-transform: scale(1.2) rotate(3deg);
  -o-transform: scale(1.2) rotate(3deg);
  -ms-transform: scale(1.2) rotate(3deg);
  -webkit-transform: scale(1.2) rotate(3deg);
  transform: scale(1.2) rotate(3deg);
}
.case-study-item:hover .case-study-thumb .case-study-link-icon a {
  visibility: visible;
  opacity: 1;
  right: 28px;
}
.case-study-item:hover .case-study-thumb .case-study-details {
  -moz-transform: perspective(0) rotateX(0);
  -o-transform: perspective(0) rotateX(0);
  -ms-transform: perspective(0) rotateX(0);
  -webkit-transform: perspective(0) rotateX(0);
  transform: perspective(0) rotateX(0);
  opacity: 1;
  bottom: 10px;
  left: 30px;
  opacity: 1;
}
.case-study-item:hover .case-study-thumb:after {
  opacity: 1;
}
.case-study-item:hover .case-study-thumb:before {
  -moz-transform: perspective(0) rotateX(0);
  -o-transform: perspective(0) rotateX(0);
  -ms-transform: perspective(0) rotateX(0);
  -webkit-transform: perspective(0) rotateX(0);
  transform: perspective(0) rotateX(0);
}

.service-box {
  background: #fff;
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;
  box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.07);
  position: relative;
  overflow: hidden;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}

.service-box:hover {
  -moz-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.service-icon span:before {
  font-size: 60px;
  margin-top: 30px;
  display: inline-block;
  margin-bottom: 35px;
  color: #0c59db;
}

.service-box .service-content .title h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

.service-box:before {
  content: "";
  background: rgba(24, 49, 95, 0.8);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}

.service-box:after {
  position: absolute;
  /* background: url(); */
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  content: "";
  background-size: cover;
  background-position: center center;
  opacity: 0;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}

.service-box:hover:before, .service-box:hover:after {
  opacity: 1;
}

.service-box:hover .service-icon span:before {
  position: relative;
  z-index: 2;
  color: #fff;
}

.service-box:hover .service-content .title h3 {
  color: #fff;
  position: relative;
  z-index: 2;
}

.service-box:hover .service-content .para p {
  position: relative;
  z-index: 2;
  color: #fff;
}

/*========================================*/
/*------------- [_Divider] ---------------*/
/*========================================*/
.text-divider .large-text {
  font-size: 48px;
  line-height: 1.2;
  text-transform: uppercase;
}

.call-us-now {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  padding: 30px 40px;
  border-radius: 12px;
}

.call-us-now:before {
  position: inherit;
  content: "\f18e";
  font-family: "Flaticon";
  color: #fff;
  font-size: 92px;
  left: 30px;
  top: 50px;
  opacity: 0.2;
}

/*========================================*/
/*-------------- [_Typography] -----------*/
/*========================================*/
/*Margin Top */
.mrt-0 {
  margin-top: 0px;
}

.mrt-5 {
  margin-top: 5px;
}

.mrt-10 {
  margin-top: 10px;
}

.mrt-15 {
  margin-top: 15px;
}

.mrt-20 {
  margin-top: 20px;
}

.mrt-25 {
  margin-top: 25px;
}

.mrt-30 {
  margin-top: 30px;
}

.mrt-35 {
  margin-top: 35px;
}

.mrt-40 {
  margin-top: 40px;
}

.mrt-45 {
  margin-top: 45px;
}

.mrt-50 {
  margin-top: 50px;
}

.mrt-55 {
  margin-top: 55px;
}

.mrt-60 {
  margin-top: 60px;
}

.mrt-65 {
  margin-top: 65px;
}

.mrt-70 {
  margin-top: 70px;
}

.mrt-75 {
  margin-top: 75px;
}

.mrt-80 {
  margin-top: 80px;
}

.mrt-85 {
  margin-top: 85px;
}

.mrt-90 {
  margin-top: 90px;
}

.mrt-95 {
  margin-top: 95px;
}

.mrt-100 {
  margin-top: 100px;
}

.mrt-105 {
  margin-top: 105px;
}

.mrt-110 {
  margin-top: 110px;
}

.mrt-115 {
  margin-top: 115px;
}

.mrt-120 {
  margin-top: 120px;
}

.mrt-125 {
  margin-top: 125px;
}

.mrt-130 {
  margin-top: 130px;
}

.mrt-135 {
  margin-top: 135px;
}

.mrt-140 {
  margin-top: 140px;
}

.mrt-145 {
  margin-top: 145px;
}

.mrt-150 {
  margin-top: 150px;
}

.minus-mrt-130 {
  margin-top: -130px;
}

.minus-mrt-60 {
  margin-top: -60px;
}

/*Margin Right */
.mrr-0 {
  margin-right: 0px;
}

.mrr-5 {
  margin-right: 5px;
}

.mrr-10 {
  margin-right: 10px;
}

.mrr-15 {
  margin-right: 15px;
}

.mrr-20 {
  margin-right: 20px;
}

.mrr-25 {
  margin-right: 25px;
}

.mrr-30 {
  margin-right: 30px;
}

.mrr-35 {
  margin-right: 35px;
}

.mrr-40 {
  margin-right: 40px;
}

.mrr-45 {
  margin-right: 45px;
}

.mrr-50 {
  margin-right: 50px;
}

.mrr-55 {
  margin-right: 55px;
}

.mrr-60 {
  margin-right: 60px;
}

.mrr-65 {
  margin-right: 65px;
}

.mrr-70 {
  margin-right: 70px;
}

.mrr-75 {
  margin-right: 75px;
}

.mrr-80 {
  margin-right: 80px;
}

.mrr-85 {
  margin-right: 85px;
}

.mrr-90 {
  margin-right: 90px;
}

.mrr-95 {
  margin-right: 95px;
}

.mrr-100 {
  margin-right: 100px;
}

.mrr-105 {
  margin-right: 105px;
}

.mrr-110 {
  margin-right: 110px;
}

.mrr-115 {
  margin-right: 115px;
}

.mrr-120 {
  margin-right: 120px;
}

.mrr-125 {
  margin-right: 125px;
}

.mrr-130 {
  margin-right: 130px;
}

.mrr-135 {
  margin-right: 135px;
}

.mrr-140 {
  margin-right: 140px;
}

.mrr-145 {
  margin-right: 145px;
}

.mrr-150 {
  margin-right: 150px;
}

/*Margin Bottom */
.mrb-0 {
  margin-bottom: 0px;
}

.mrb-5 {
  margin-bottom: 5px;
}

.mrb-10 {
  margin-bottom: 10px;
}

.mrb-15 {
  margin-bottom: 15px;
}

.mrb-20 {
  margin-bottom: 20px;
}

.mrb-25 {
  margin-bottom: 25px;
}

.mrb-30 {
  margin-bottom: 30px;
}

.mrb-35 {
  margin-bottom: 35px;
}

.mrb-40 {
  margin-bottom: 40px;
}

.mrb-45 {
  margin-bottom: 45px;
}

.mrb-50 {
  margin-bottom: 50px;
}

.mrb-55 {
  margin-bottom: 55px;
}

.mrb-60 {
  margin-bottom: 60px;
}

.mrb-65 {
  margin-bottom: 65px;
}

.mrb-70 {
  margin-bottom: 70px;
}

.mrb-75 {
  margin-bottom: 75px;
}

.mrb-80 {
  margin-bottom: 80px;
}

.mrb-85 {
  margin-bottom: 85px;
}

.mrb-90 {
  margin-bottom: 90px;
}

.mrb-95 {
  margin-bottom: 95px;
}

.mrb-100 {
  margin-bottom: 100px;
}

.mrb-105 {
  margin-bottom: 105px;
}

.mrb-110 {
  margin-bottom: 110px;
}

.mrb-115 {
  margin-bottom: 115px;
}

.mrb-120 {
  margin-bottom: 120px;
}

.mrb-125 {
  margin-bottom: 125px;
}

.mrb-130 {
  margin-bottom: 130px;
}

.mrb-135 {
  margin-bottom: 135px;
}

.mrb-140 {
  margin-bottom: 140px;
}

.mrb-145 {
  margin-bottom: 145px;
}

.mrb-150 {
  margin-bottom: 150px;
}

/*Margin Left */
.mrl-0 {
  margin-left: 0px;
}

.mrl-5 {
  margin-left: 5px;
}

.mrl-10 {
  margin-left: 10px;
}

.mrl-15 {
  margin-left: 15px;
}

.mrl-20 {
  margin-left: 20px;
}

.mrl-25 {
  margin-left: 25px;
}

.mrl-30 {
  margin-left: 30px;
}

.mrl-35 {
  margin-left: 35px;
}

.mrl-40 {
  margin-left: 40px;
}

.mrl-45 {
  margin-left: 45px;
}

.mrl-50 {
  margin-left: 50px;
}

.mrl-55 {
  margin-left: 55px;
}

.mrl-60 {
  margin-left: 60px;
}

.mrl-65 {
  margin-left: 65px;
}

.mrl-70 {
  margin-left: 70px;
}

.mrl-75 {
  margin-left: 75px;
}

.mrl-80 {
  margin-left: 80px;
}

.mrl-85 {
  margin-left: 85px;
}

.mrl-90 {
  margin-left: 90px;
}

.mrl-95 {
  margin-left: 95px;
}

.mrl-100 {
  margin-left: 100px;
}

.mrl-105 {
  margin-left: 105px;
}

.mrl-110 {
  margin-left: 110px;
}

.mrl-115 {
  margin-left: 115px;
}

.mrl-120 {
  margin-left: 120px;
}

.mrl-125 {
  margin-left: 125px;
}

.mrl-130 {
  margin-left: 130px;
}

.mrl-135 {
  margin-left: 135px;
}

.mrl-140 {
  margin-left: 140px;
}

.mrl-145 {
  margin-left: 145px;
}

.mrl-150 {
  margin-left: 150px;
}

/*Padding Top */
.pdt-0 {
  padding-top: 0px;
}

.pdt-5 {
  padding-top: 5px;
}

.pdt-10 {
  padding-top: 10px;
}

.pdt-15 {
  padding-top: 15px;
}

.pdt-20 {
  padding-top: 20px;
}

.pdt-25 {
  padding-top: 25px;
}

.pdt-30 {
  padding-top: 30px;
}

.pdt-35 {
  padding-top: 35px;
}

.pdt-40 {
  padding-top: 40px;
}

.pdt-45 {
  padding-top: 45px;
}

.pdt-50 {
  padding-top: 50px;
}

.pdt-55 {
  padding-top: 55px;
}

.pdt-60 {
  padding-top: 60px;
}

.pdt-65 {
  padding-top: 65px;
}

.pdt-70 {
  padding-top: 70px;
}

.pdt-75 {
  padding-top: 75px;
}

.pdt-80 {
  padding-top: 80px;
}

.pdt-85 {
  padding-top: 85px;
}

.pdt-90 {
  padding-top: 90px;
}

.pdt-95 {
  padding-top: 95px;
}

.pdt-100 {
  padding-top: 100px;
}

.pdt-105 {
  padding-top: 105px;
}

.pdt-110 {
  padding-top: 110px;
}

.pdt-115 {
  padding-top: 115px;
}

.pdt-120 {
  padding-top: 120px;
}

.pdt-125 {
  padding-top: 125px;
}

.pdt-130 {
  padding-top: 130px;
}

.pdt-135 {
  padding-top: 135px;
}

.pdt-140 {
  padding-top: 140px;
}

.pdt-145 {
  padding-top: 145px;
}

.pdt-150 {
  padding-top: 150px;
}

/*Padding Right */
.pdr-0 {
  padding-right: 0px;
}

.pdr-5 {
  padding-right: 5px;
}

.pdr-10 {
  padding-right: 10px;
}

.pdr-15 {
  padding-right: 15px;
}

.pdr-20 {
  padding-right: 20px;
}

.pdr-25 {
  padding-right: 25px;
}

.pdr-30 {
  padding-right: 30px;
}

.pdr-35 {
  padding-right: 35px;
}

.pdr-40 {
  padding-right: 40px;
}

.pdr-45 {
  padding-right: 45px;
}

.pdr-50 {
  padding-right: 50px;
}

.pdr-55 {
  padding-right: 55px;
}

.pdr-60 {
  padding-right: 60px;
}

.pdr-65 {
  padding-right: 65px;
}

.pdr-70 {
  padding-right: 70px;
}

.pdr-75 {
  padding-right: 75px;
}

.pdr-80 {
  padding-right: 80px;
}

.pdr-85 {
  padding-right: 85px;
}

.pdr-90 {
  padding-right: 90px;
}

.pdr-95 {
  padding-right: 95px;
}

.pdr-100 {
  padding-right: 100px;
}

.pdr-105 {
  padding-right: 105px;
}

.pdr-110 {
  padding-right: 110px;
}

.pdr-115 {
  padding-right: 115px;
}

.pdr-120 {
  padding-right: 120px;
}

.pdr-125 {
  padding-right: 125px;
}

.pdr-130 {
  padding-right: 130px;
}

.pdr-135 {
  padding-right: 135px;
}

.pdr-140 {
  padding-right: 140px;
}

.pdr-145 {
  padding-right: 145px;
}

.pdr-150 {
  padding-right: 150px;
}

/*Padding Bottom */
.pdb-0 {
  padding-bottom: 0px;
}

.pdb-5 {
  padding-bottom: 5px;
}

.pdb-10 {
  padding-bottom: 10px;
}

.pdb-15 {
  padding-bottom: 15px;
}

.pdb-20 {
  padding-bottom: 20px;
}

.pdb-25 {
  padding-bottom: 25px;
}

.pdb-30 {
  padding-bottom: 30px;
}

.pdb-35 {
  padding-bottom: 35px;
}

.pdb-40 {
  padding-bottom: 40px;
}

.pdb-45 {
  padding-bottom: 45px;
}

.pdb-50 {
  padding-bottom: 50px;
}

.pdb-55 {
  padding-bottom: 55px;
}

.pdb-60 {
  padding-bottom: 60px;
}

.pdb-65 {
  padding-bottom: 65px;
}

.pdb-70 {
  padding-bottom: 70px;
}

.pdb-75 {
  padding-bottom: 75px;
}

.pdb-80 {
  padding-bottom: 80px;
}

.pdb-85 {
  padding-bottom: 85px;
}

.pdb-90 {
  padding-bottom: 90px;
}

.pdb-95 {
  padding-bottom: 95px;
}

.pdb-100 {
  padding-bottom: 100px;
}

.pdb-105 {
  padding-bottom: 105px;
}

.pdb-110 {
  padding-bottom: 110px;
}

.pdb-115 {
  padding-bottom: 115px;
}

.pdb-120 {
  padding-bottom: 120px;
}

.pdb-125 {
  padding-bottom: 125px;
}

.pdb-130 {
  padding-bottom: 130px;
}

.pdb-135 {
  padding-bottom: 135px;
}

.pdb-140 {
  padding-bottom: 140px;
}

.pdb-145 {
  padding-bottom: 145px;
}

.pdb-150 {
  padding-bottom: 150px;
}

.pdt-195 {
  padding-top: 195px;
}

.pdt-165 {
  padding-top: 165px;
}

.pdb-180 {
  padding-bottom: 180px;
}

.pdb-210 {
  padding-bottom: 210px;
}

.pdb-300 {
  padding-bottom: 300px;
}

/*Padding Left */
.pdl-0 {
  padding-left: 0px;
}

.pdl-5 {
  padding-left: 5px;
}

.pdl-10 {
  padding-left: 10px;
}

.pdl-15 {
  padding-left: 15px;
}

.pdl-20 {
  padding-left: 20px;
}

.pdl-25 {
  padding-left: 25px;
}

.pdl-30 {
  padding-left: 30px;
}

.pdl-35 {
  padding-left: 35px;
}

.pdl-40 {
  padding-left: 40px;
}

.pdl-45 {
  padding-left: 45px;
}

.pdl-50 {
  padding-left: 50px;
}

.pdl-55 {
  padding-left: 55px;
}

.pdl-60 {
  padding-left: 60px;
}

.pdl-65 {
  padding-left: 65px;
}

.pdl-70 {
  padding-left: 70px;
}

.pdl-75 {
  padding-left: 75px;
}

.pdl-80 {
  padding-left: 80px;
}

.pdl-85 {
  padding-left: 85px;
}

.pdl-90 {
  padding-left: 90px;
}

.pdl-95 {
  padding-left: 95px;
}

.pdl-100 {
  padding-left: 100px;
}

.pdl-105 {
  padding-left: 105px;
}

.pdl-110 {
  padding-left: 110px;
}

.pdl-115 {
  padding-left: 115px;
}

.pdl-120 {
  padding-left: 120px;
}

.pdl-125 {
  padding-left: 125px;
}

.pdl-130 {
  padding-left: 130px;
}

.pdl-135 {
  padding-left: 135px;
}

.pdl-140 {
  padding-left: 140px;
}

.pdl-145 {
  padding-left: 145px;
}

.pdl-150 {
  padding-left: 150px;
}

/*Font Weight */
.f-weight-100 {
  font-weight: 100;
}

.f-weight-200 {
  font-weight: 200;
}

.f-weight-300 {
  font-weight: 300;
}

.f-weight-400 {
  font-weight: 400;
}

.f-weight-500 {
  font-weight: 500;
}

.f-weight-600 {
  font-weight: 600;
}

.f-weight-700 {
  font-weight: 700;
}

.f-weight-800 {
  font-weight: 800;
}

.f-weight-900 {
  font-weight: 900;
}

.vh {
  height: 100vh;
}

.d-flex {
  align-items: center;
  display: flex;
}

.font-30 {
  font-size: 30px;
}

.font-54 {
  font-size: 54px;
  line-height: 64px;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index--1 {
  z-index: -1;
}

.border-right-silver2 {
  border-right: 1px solid #b7b7b8;
}

@media (max-width: 991px) {
  .sidebar-right {
    padding-top: 50px !important;
    order: 1;
    width: 100%;
  }

  .first-priority {
    order: -1;
  }
}
/*Google Font Classes */
.f-family1 {
  font-family: "Poppins", sans-serif;
}

.f-family2 {
  font-family: "Caladea", serif;
}

/*Data Overlay */
[data-overlay-dark],
[data-overlay-light] {
  position: relative;
}

[data-overlay-dark] .container,
[data-overlay-light] .container {
  position: relative;
  z-index: 2;
}

[data-overlay-dark]:before,
[data-overlay-light]:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

[data-overlay-dark]:before {
  background: #131c34;
}

[data-overlay-light]:before {
  background: #fff;
}

[data-overlay-dark="0"]:before,
[data-overlay-light="0"]:before {
  opacity: 0;
}

[data-overlay-dark="1"]:before,
[data-overlay-light="1"]:before {
  opacity: 0.1;
}

[data-overlay-dark="2"]:before,
[data-overlay-light="2"]:before {
  opacity: 0.2;
}

[data-overlay-dark="3"]:before,
[data-overlay-light="3"]:before {
  opacity: 0.3;
}

[data-overlay-dark="4"]:before,
[data-overlay-light="4"]:before {
  opacity: 0.4;
}

[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
  opacity: 0.5;
}

[data-overlay-dark="6"]:before,
[data-overlay-light="6"]:before {
  opacity: 0.6;
}

[data-overlay-dark="7"]:before,
[data-overlay-light="7"]:before {
  opacity: 0.7;
}

[data-overlay-dark="8"]:before,
[data-overlay-light="8"]:before {
  opacity: 0.8;
}

[data-overlay-dark="9"]:before,
[data-overlay-light="9"]:before {
  opacity: 0.9;
}

[data-overlay-dark="94"]:before,
[data-overlay-light="94"]:before {
  opacity: 0.94;
}

[data-overlay-dark="10"]:before,
[data-overlay-light="10"]:before {
  opacity: 1;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.long-gap-left {
  margin-left: 370px;
  overflow: hidden;
}

.f-right {
  float: right;
}

.f-left {
  float: left;
}

.text-underline {
  text-decoration: underline;
}

.display-inline-block {
  display: inline-block;
}

.text-white {
  color: #ffffff;
}

.bg-white {
  background: #ffffff;
}

.bg-light-pink {
  background: #faf3f3;
}

.bg-silver {
  background: #ECF1F8;
}

.bg-silver-light {
  background: #F7F8FC;
}

.bg-silver-deep {
  background: #ecdaee;
}

.bg-gray {
  background: #f7f7f7;
}

.text-silver {
  color: #f5f3f1;
}

.text-light-gray {
  color: #a7a8b4;
}

.text-gray {
  color: #a4a4aa;
}

.text-gray-light {
  color: #CCCCCC;
}

.text-dark-light {
  color: #808093;
}

.section-white-typo * {
  color: #fff !important;
}
/* 
.section-bg1 {
  background: url();
}

.section-bg2 {
  background: url();
}

.section-bg3 {
  background: url();
}

.section-bg4 {
  background: url();
}

.section-bg5 {
  background: url();
} */

.abs-bg1 {
  background: url(../images/bg/abs-bg1.png);
}

.abs-bg2 {
  background: url(../images/bg/abs-bg2.png);
}

.contact-from-bg {
  background: url(../images/bg/contact-form-bg.png);
}

[class*=section-bg] {
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.section-title-line {
  position: relative;
  display: inline-block;
}

.section-title-line:before {
  content: "";
  background: #e41f7a;
  height: 3px;
  width: 45%;
  position: absolute;
  right: 0;
  bottom: 5px;
  display: inline-block;
  left: 0;
  margin: 0 auto;
}

.section-title-line.line-white:before {
  background: #fff;
}

.solid-line-bottom {
  position: relative;
  display: inline-block;
}

.solid-line-bottom:before {
  content: "";
  background: linear-gradient(45deg, #0E90D5, #3032A2) !important;
  height: 5px;
  width: 15%;
  position: absolute;
  right: 0;
  bottom: -20px;
  display: inline-block;
  left: 0;
  z-index: -1;
  border-radius: 15px;
}

.element-shadow {
  box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.3);
}

.element-shadow-long {
  box-shadow: 0px 20px 19px 0px rgba(0, 0, 0, 0.14);
}

.bg-cover {
  background-size: cover;
}

.divider-gap {
  padding: 11% 16% 9% 12%;
}

.vertical-align-middle {
  vertical-align: middle;
}

.half-sec {
  position: relative;
  overflow: hidden;
}

.half-sec:before {
  content: "";
  background: #fdeeec;
  position: absolute;
  height: 95%;
  width: 48%;
  left: -240px;
  transform: skew(-30deg, 15deg);
  top: 0;
  opacity: 0.6;
}

.intersection-section {
  margin-top: -160px;
  padding-top: 240px;
}

.border-top-bottom {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}


