/*========================================*/
/*------------- [_Home_Slider] -----------*/
/*========================================*/

.home-carousel .slide-item {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 190px 0px 290px 0px;
}
.home-carousel .slide-item .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}
.banner-section{
  position: relative;
}

.home-carousel{
  position: relative;
}

.home-carousel .slide-item{
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 200px 0px 200px 0px;
}

.home-carousel .slide-item:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(-180deg, rgba(22, 3, 1, 0), rgb(9, 51, 79) 150%);
  top: 0px;
  right: 0px;
  z-index: 1;
}

.home-carousel .slide-item .image-layer{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-carousel .content-box{
  position: relative;
  display: block;
  z-index:5;
  width: 100%;
}

.home-carousel .content-box h1{
  position: relative;
  display: block;
  color: #fff;
  font-size: 56px;
  line-height: 62px;
  font-weight: 900;
  margin-bottom: 30px;
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.home-carousel .active .content-box h1{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  -ms-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
}

.home-carousel .content-box p{
  position: relative;
  display: block;
  color: #fff;
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 36px;
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 1300ms ease;
  -moz-transition: all 1300ms ease;
  -ms-transition: all 1300ms ease;
  -o-transition: all 1300ms ease;
  transition: all 1300ms ease;
}

.home-carousel .active .content-box p{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1300ms;
  -moz-transition-delay: 1300ms;
  -ms-transition-delay: 1300ms;
  -o-transition-delay: 1300ms;
  transition-delay: 1300ms;
}

.banner-section .home-carousel .content-box a.theme-btn{
  padding: 14px 47px;
}

.home-carousel .content-box .btn-box{
  position: relative;
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 1600ms ease;
  -moz-transition: all 1600ms ease;
  -ms-transition: all 1600ms ease;
  -o-transition: all 1600ms ease;
  transition: all 1600ms ease;
}

.home-carousel .active .content-box .btn-box{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1600ms;
  -moz-transition-delay: 1600ms;
  -ms-transition-delay: 1600ms;
  -o-transition-delay: 1600ms;
  transition-delay: 1600ms;
}