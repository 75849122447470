/*========================================*/
/*--------------- [_Global] --------------*/
/*========================================*/
.preloader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background-color: #ffffff;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(../images/preloader.svg);
	background-size: 90px;
}
.section-gap {
	padding: 110px 0;
}
.section-gap-onepage {
    padding: 210px 0px 110px 0px !important;
}
section.anim-object {
	overflow: hidden;
}
.border-radius-default {
	border-radius: 6px;
}
.bg-pos-center-bottom {
	background-position: center bottom;
}
.table {
	display: table;
	width: 100%;
	height: 100%;
}
.table-cell {
	display: table-cell;
	width: 100%;
	height: auto;
	vertical-align: middle;
}
.slide-bg-1 {
	background-image: url(../images/bg/bg1.jpg);
}
.slide-bg-2 {
	background-image: url(../images/bg/bg2.jpg);
}
.slide-bg-3 {
	background-image: url(../images/bg/bg3.jpg);
}

// Owl Carousel Nav Prev
.homepage-slides .owl-nav button.owl-prev,
.homepage-slides .owl-nav button.owl-next {
	font-size: 20px;
	height: 50px;
	left: 50px;
	line-height: 50px;
	position: absolute;
	text-align: center;
	top: 50%;
	width: 50px;
	background: #fff;
	transform: translateY(-50%);
	transition: all 600ms ease 0ms;
	opacity: 0;
	visibility: hidden;
}
.homepage-slides:hover .owl-nav button.owl-prev,
.homepage-slides:hover .owl-nav button.owl-next {
	opacity: 1;
	visibility: visible;
}
.homepage-slides .owl-nav button.owl-next {
	left: auto;
	right: 50px;
}
// Social List
.social-list {
	li {
		display: inline;
		float: left;
		margin-right: 15px;
		a {
			background: #303743;
			height: 42px;
			width: 42px;
			display: inline-block;
			color: #fff;
			text-align: center;
			border-radius: 50%;
			line-height: 42px;
			font-size: 16px;
			&:hover {
				background: $bg-gradient-color;
			}
		}
	}
	&.list-primary-color {
		li {
			a {
				background: $bg-primary-color;
			}
		}
	}
	&.list-flat {
		li {
			a {
				border-radius: 0;
			}
		}
	}
	&.list-lg {
		li {
			a {
				height: 48px;
				width: 48px;
				font-size: 18px;
				line-height: 48px;
			}
		}
	}
	&.list-sm {
		li {
			margin-right: 10px;
			height: 34px;
			width: 34px;
			font-size: 14px;
			line-height: 34px;
			a {
				height: 34px;
				width: 34px;
				font-size: 14px;
				line-height: 34px;
			}
		}
	}
}
// Order List
.order-list {
	li {
		display: inherit;
		position: relative;
		padding-left: 30px;
		margin: 12px 0px;
		&:before {
			position: absolute;
			content: '\f192';
			left: 0px;
			top: 0px;
			font-family: "Font Awesome 5 Free";
		}
		&:first-child {
			margin-top: 0;
		}
	}
	&.primary-color {
		li {
			&:before {
				color: $bg-primary-color;
				font-weight: 500;
			}
		}
	}
}
.list-items {
	li {
		display: inherit;
		padding: 5px 0;
	}
}
#curve {
	position: absolute;
	top: 0;
	left: 0;
	transform: rotate(180deg);
}

#curve path {
	fill: #fff;
}
// Google Map
.google-map {
	height: 465px;
}
.google-map.map-full-height {
	height: 100%;
}
.mapouter {
	position: relative;
	text-align: right;
	height: 100%;
	width: 100%;
}
.mapouter.fixed-height {
	height: 480px;
}
.gmap_canvas {
	overflow: hidden;
	background: none !important;
	height: 100%;
	width: auto;
}
.mapouter iframe {
	width: 100%;
	height: 100%;
}
// Back-To-Top
.back-to-top {
	position: fixed;
	right: 36px;
	bottom: 42px;
	width: 42px;
	height: 42px;
	color: #fff;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	z-index: 99;
	font-size: 22px;
	cursor: pointer;
	background-color: $bg-gradient-color;
	-webkit-animation: white-shadow .8s infinite, red-shadow .8s infinite;
	animation: white-shadow .8s infinite, red-shadow .8s infinite;
	@include box-shadow(0 10px 33px rgba(0, 0, 0, 0.1));
	display: none;
}
@keyframes white-shadow {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .5);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, .5);
		-webkit-transition: box-shadow .3s ease-in-out;
		-webkit-transition: -webkit-box-shadow .3s ease-in-out;
		-o-transition: box-shadow .3s ease-in-out;
		transition: box-shadow .3s ease-in-out;
		transition: box-shadow .3s ease-in-out, -webkit-box-shadow .3s ease-in-out
	}
	100% {
		-webkit-box-shadow: 0 0 0 30px transparent;
		box-shadow: 0 0 0 30px transparent;
		-webkit-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		-webkit-transition: box-shadow .4s ease-in-out;
		-webkit-transition: -webkit-box-shadow .4s ease-in-out;
		-o-transition: box-shadow .4s ease-in-out;
		transition: box-shadow .4s ease-in-out;
		transition: box-shadow .4s ease-in-out, -webkit-box-shadow .4s ease-in-out
	}
}
@-webkit-keyframes red-shadow {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(31, 100, 188, .4);
		box-shadow: 0 0 0 0 rgba(31, 100, 188, .4)
	}
	100% {
		-webkit-box-shadow: 0 0 0 30px transparent;
		box-shadow: 0 0 0 30px transparent
	}
}
@keyframes red-shadow {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(31, 100, 188, .4);
		box-shadow: 0 0 0 0 rgba(31, 100, 188, .4)
	}
	100% {
		-webkit-box-shadow: 0 0 0 30px transparent;
		box-shadow: 0 0 0 30px transparent
	}
}

// Text Under Line
.text-under-line {
	position: relative;
	display: inline-block;
}
.text-under-line:before {
	content: '';
	background-color: $bg-primary-color;
	height: 2px;
	width: 4.75rem;
	top: 15px;
	right: -90px;
	position: absolute;
}

// Popup Video
.video-popup {
	position: relative;
}
.popup-youtube {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	text-align: center;
	transform: translateY(-50%);
	color: #fff;
}
.popup-youtube:hover {
	color: #fff;
}
.video-popup a i::before {
	font-size: 90px;
	background: $bg-primary-color;
	border-radius: 50%;
	line-height: 100px;
}

.video-popup-left a {
	height: 95px;
	width: 95px;
	text-align: center;
	line-height: 95px;
	background: #fff;
	font-size: 24px;
	color: #19203f;
	position: relative;
	z-index: 10;
	display: inline-block;
	border-radius: 95px;
	@include transition(all 0.4s ease-in-out);
	// transition: all .3s ease;
}
.video-popup-left a::before {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	border: 1px solid #fff;
	z-index: -1;
	opacity: 0;
	-webkit-animation: pulse-border-2 1.5s linear infinite;
	-moz-animation: pulse-border-2 1.5s linear infinite;
	-o-animation: pulse-border-2 1.5s linear infinite;
	animation: pulse-border-2 1.5s linear infinite;
	border-radius: 95px;
}
.video-popup-left a::after {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	border: 1px solid #fff;
	z-index: -1;
	opacity: 0;
	-webkit-animation: pulse-border 1s linear infinite;
	-moz-animation: pulse-border 1s linear infinite;
	-o-animation: pulse-border 1s linear infinite;
	animation: pulse-border 1s linear infinite;
	border-radius: 95px;
}
@keyframes pulse-border {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(1.3);
		opacity: 0;
	}
}
@keyframes pulse-border-2 {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(1.5);
		opacity: 0;
	}
}
.pulse-animation,
.pulse-animation:before,
.pulse-animation:after {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 90px;
	width: 90px;
	@include translate(-50%, -50%);
	@include border-radius(50%);
	@include box-shadow(0 0 0 0 rgba($bg-primary-color, .6));
	@include animation(pulse-animation 3s infinite);
}
.pulse-animation:before {
	@include animation-delay(.9s);
	content: "";
	position: absolute;
}
.pulse-animation:after {
	@include animation-delay(.6s);
	content: "";
	position: absolute;
}

@-webkit-keyframes pulse-animation {
	70% {
		@include box-shadow(0 0 0 60px rgba(255, 255, 255, 0));
	}
	100% {
		@include box-shadow(0 0 0 0 rgba(255, 255, 255, 0));
	}
}

@keyframes pulse-animation {
	70% {
		@include box-shadow(0 0 0 60px rgba(255, 255, 255, 0));
	}
	100% {
		@include box-shadow(0 0 0 0 rgba(255, 255, 255, 0));
	}
}

/*Owl Carousel Nav Prev*/
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
	font-size: 20px;
	height: 50px;
	border-radius: 50%;
	left: 45px;
	line-height: 50px;
	position: absolute;
	text-align: center;
	top: 50%;
	width: 50px;
	background: $bg-gradient-color;
	@include translate(0, -50%);
	@include transition(all 600ms ease 0ms);
	opacity: 0;
	visibility: hidden;
}

.owl-carousel:hover .owl-nav button.owl-prev,
.owl-carousel:hover .owl-nav button.owl-next {
	opacity: 1;
	visibility: visible;
}
.owl-carousel:hover .owl-nav button.owl-prev {
	left: 15px;
}
.owl-carousel:hover .owl-nav button.owl-next {
	right: 15px;
}
.owl-carousel .owl-nav button.owl-next {
	left: auto;
	right: 45px;
}
.owl-carousel .owl-nav button i {
	color: #fff;
}

//Mean Menu
.mean-container a.meanmenu-reveal {
	border: 1px solid $bg-primary-color;
	color: $bg-primary-color;
	margin-top: -45px;
}
.mean-container a.meanmenu-reveal span {
	background: $bg-primary-color;
}

//Pagination
.pagination-nav {
	.pagination-list> {
		li {
			text-align: center;
			margin-right: 8px;
			&:last-child {
				margin-right: 0;
			}
			>a {
				color: #565151;
				font-weight: 500;
				height: 54px;
				width: 54px;
				line-height: 54px;
				display: inline-block;
				border: 1px solid #ddd;
				position: relative;
				border-radius: 50%;
			}
			&:hover {
				a {
					border: 1px solid transparent;
					background: $bg-primary-color;
					color: #fff;
				}
			}
			&.active a {
				border: 1px solid transparent;
				background: $bg-primary-color;
				color: #fff;
			}
		}
	}
}


//Skills
.skills {
	.skill-item {
		position: relative;
		margin-bottom: 20px;
		.skill-header {
			position: relative;
			margin-bottom: 5px;
			.skill-percentage {
				position: absolute;
				right: 0;
				top: 1px;
				font-size: 15px;
				font-weight: 600;
				color: $bg-secondary-color;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
		.skill-bar {
			position: relative;
			width: 100%;
			.bar-inner {
				position: relative;
				width: 100%;
				height: 20px;
				background: #EBEAF0;
				border-radius: 45px;
				.bar {
					position: absolute;
					left: 0;
					top: 0;
					width: 0;
					height: 20px;
					background: $bg-gradient-reverse-color;
					-webkit-transition: all 2000ms ease;
					transition: all 2000ms ease;
					border-radius: 45px;
					&::before {
						position: absolute;
						content: '';
						right: 0;
						height: 20px;
						width: 5px;
						background: #fff;
						top: 0;
					}
				}
			}
		}
	}
}


//Percentage
.percentage {
	font-size: 12px;
	float: right;
}

//Single Post Navigation
.single-post-navigation {
	.navigation-links {
		a {
			@include transition(all 300ms ease-out 0s);
		}
		.nav-next {
			a {
				i {
					@include transition(all 300ms ease-out 0s);
					margin-left: 8px;
				}
				&:hover {
					i {
						margin-left: 3px;
					}
				}
			}
		}
		.nav-previous {
			a {
				i {
					@include transition(all 300ms ease-out 0s);
					margin-right: 8px;
				}
				&:hover {
					i {
						margin-right: 3px;
					}
				}
			}
		}
	}
}

//404 page Styles
.error-area {
	background-size: cover;
	background-position: center center;
}
.error-inner .error-title {
	font-size: 190px;
	line-height: 240px;
}
.error-inner .error-text {
	font-size: 40px;
}
.error-inner p {
	max-width: 545px;
	margin: 20px auto 30px;
}
.pintarest-list {
	display: flex;
	flex-wrap: wrap;
}
.pintarest-list li {
	width: 80px;
	height: 80px;
	padding: 5px;
}
// scrollUp
#scrollUp {
	right: 36px;
	bottom: 42px;
	width: 42px;
	height: 42px;
	color: #fff;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	z-index: 99;
	font-size: 22px;
	cursor: pointer;
	background-color: $bg-primary-color;
	-webkit-animation: white-shadow .8s infinite, red-shadow .8s infinite;
	animation: white-shadow .8s infinite, red-shadow .8s infinite;
	@include box-shadow(0 10px 33px rgba(0, 0, 0, 0.1));
}

// client
.client-item {
	padding: 15px;
	@include transition(all 300ms ease-out 0s);
}
.client-item img {
	@include transition(all 300ms ease-out 0s);
}
.fa-phone.alt {
	@include transform(rotate(90deg));
}
.anim-object,
.anim-object2 {
	position: relative;
}
.anim-object:before {
	position: absolute;
	content: "";
	background-image: url(../images/objects/1.png);
	height: 260px;
	width: 260px;
	top: 10%;
	right: 10%;
	background-size: contain;
	animation-name: spinInfinit;
	animation-duration: 20s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
.fa-phone.alt {
	@include transform(rotate(90deg));
}
.anim-object2:before {
	position: absolute;
	content: "";
	background-image: url(../images/objects/3.png);
	height: 325px;
	width: 325px;
	top: 35%;
	left: 1%;
	background-size: contain;
	background-repeat: no-repeat;
	animation-name: spinInfinit;
	animation-duration: 60s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	opacity: 0.8;
}
