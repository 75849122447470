/*========================================*/
/*--------------- [_Header] --------------*/
/*========================================*/

.logo {
	padding-top: 1.3125rem;
}
.logo img {
	height: 52px;
}
/*Header Style One*/
.header-style-one {
	.header-wrapper {
		.header-navigation-area {
			background: $bg-silver;
			transition: all 0.4s ease-out 0s;
			.main-menu {
				ul {
					li {
						display: inline-block;
						margin-right: 45px;
						position: relative;
						@include transition(all 300ms ease-out 0s);
						&:last-child {
							margin-right: 0;
						}
						a {
							padding: 36px 0;
							display: block;
							color: #2f2b2b;
							font-weight: 600;
						}
						&.has-sub {
							&.right-view {
								ul {
									right: -20px;
									left: auto;
								}
							}
							> a::before {
								content: "\f067";
								position: absolute;
								right: -16px;
								top: 50%;
								@include translate(0 , -13px);
								font-family: "Font Awesome 5 Free";
								font-size: 16px;
							}
						}
						&:hover {
							> a {
								color: $bg-primary-color;
							}
							ul.sub-menu {
								opacity: 1;
								visibility: visible;
								top: 100%;
								@include transform(scaleY(1));
							}
						}
						ul.sub-menu {
							position: absolute;
							width: 220px;
							background: #f8f8f8;
							z-index: 2;
							-moz-transition: all 300ms ease-out 0s;
							-webkit-transition: all 300ms ease-out 0s;
							-ms-transition: all 300ms ease-out 0s;
							-o-transition: all 300ms ease-out 0s;
							transition: all 300ms ease-out 0s;
							opacity: 0;
							visibility: hidden;
							left: 0;
							transform-origin: top;
							top: 100%;
							-webkit-transform: scaleY(0);
							-moz-transform: scaleY(0);
							-ms-transform: scaleY(0);
							-o-transform: scaleY(0);
							transform: scaleY(0);
							li {
								display: block;
								margin: 0;
								text-align: left;
								border-bottom: 1px solid #ddd;
								&:last-child {
									border-bottom: none;
								}
								a {
									padding: 8px 20px;
									font-size: 14px;
								}
								&:hover {
									> a {
										padding-left: 25px;
										background-color: $bg-primary-color;
										color: #fff;
									}
									ul.sub-menu {
										opacity: 1;
										visibility: visible;
									}
								}
								ul.sub-menu {
									left: 100%;
									top: 0;
									opacity: 0;
									visibility: hidden;
								}
								&.has-sub-child > a::before {
									content: "\f105";
									position: absolute;
									right: 10px;
									top: 50%;
									transform: translateY(-15px);
									font-family: "Font Awesome 5 Free";
									font-size: 16px;
								}
							}
						}
					}
				}
			}
			&.inner-page {
				.main-menu {
					ul {
						li {
							a {
								padding: 35px 0;
							}
						}
					}
				}
			}
			&.transparent-header {
				background: transparent;
				position: fixed;
				z-index: 9999;
				width: 100%;
				padding: 20px 0;
				top: 0;
				@include transition( all 0.3s ease-in-out);
				.main-menu {
					ul {
						li {
							a {
							}
						}
					}
				}
				&.fixed {
					background: #fff;
					position: fixed;
					top: 0;
					width: 100%;
					z-index: 9999;
					padding: 10px 0;
					@include box-shadow(0 10px 33px rgba(0, 0, 0, 0.1));
				}
			}
			&.fixed {
				position: fixed;
				top: 0;
				width: 100%;
				z-index: 9999;
				// box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
				@include box-shadow(0 5px 10px rgba(0, 0, 0, .1));
			}
		}
	}
}

/*Header Style Two*/
.header-style-two {
	.header-wrapper {
		.header-middle {
			padding: 30px 0;
			margin: 0 auto;
			position: static;
			.topbar-info-area {
				i {
					margin-right: 15px;
					color: $bg-primary-color;
					vertical-align: middle;
					height: 48px;
					width: 48px;
					line-height: 48px;
					text-align: center;
					background: $bg-silver;
					border: 1px solid #eee;
					&:before {
						font-size: 26px;
					}
				}
				a {
					font-size: 15px;
				}
			}
		}
		// Header Navigation Area Start
		.header-navigation-area {
			background: $bg-silver-light;
			position: relative;
    	z-index: 5;
			.main-menu {
				ul {
					li {
						display: inline-block;
						margin-right: 45px;
						position: relative;
						@include transition(all 300ms ease-out 0s);
						&:last-child {
							margin-right: 0;
						}
						a {
							padding: 45px 0;
							display: block;
							color: #2f2b2b;
							font-weight: 600;
						}
						&.has-sub {
							&.right-view {
								ul {
									right: -20px;
									left: auto;
								}
							}
							> a::before {
								content: "\f067";
								position: absolute;
								right: -18px;
								top: 50%;
								@include translate(0 , -13px);
								font-family: "Font Awesome 5 Free";
								font-size: 12px;
							}
						}
						&:hover {
							> a {
								color: $bg-primary-color;
							}
							ul.sub-menu {
								opacity: 1;
								visibility: visible;
								top: 100%;
								-webkit-transform: scaleY(1);
								-moz-transform: scaleY(1);
								-ms-transform: scaleY(1);
								-o-transform: scaleY(1);
								transform: scaleY(1);
							}
						}
						ul.sub-menu {
							position: absolute;
							width: 220px;
							background: $bg-silver;
							z-index: 2;
							@include transition(all 300ms ease-out 0s);
							opacity: 0;
							visibility: hidden;
							left: 0;
							transform-origin: top;
							top: 100%;
							-webkit-transform: scaleY(0);
							-moz-transform: scaleY(0);
							-ms-transform: scaleY(0);
							-o-transform: scaleY(0);
							transform: scaleY(0);
							li {
								display: block;
								margin: 0;
								text-align: left;
								border-bottom: 1px solid #ddd;
								&:last-child {
									border-bottom: none;
								}
								a {
									padding: 8px 20px;
									font-size: 14px;
								}
								&:hover {
									> a {
										padding-left: 25px;
										background: $bg-primary-color;
										color: #fff;
									}
									ul.sub-menu {
										opacity: 1;
										visibility: visible;
									}
								}
								ul.sub-menu {
									left: 100%;
									top: 0;
									opacity: 0;
									visibility: hidden;
								}
								&.has-sub-child > a::before {
									content: "\f067";
									position: absolute;
									right: 10px;
									top: 50%;
									transform: translateY(-12px);
									font-family: "Font Awesome 5 Free";
									font-size: 12px;
								}
							}
						}
					}
				}
			}
			&.inner-page {
				.main-menu {
					ul {
						li {
							a {
								padding: 35px 0;
							}
						}
					}
				}
			}
			&.transparent-header {
				background: transparent;
				position: fixed;
				z-index: 9999;
				width: 100%;
				padding: 20px 0;
				top: 0;
				@include transition( all 0.3s ease-in-out);
				.main-menu {
					ul {
						li {
							a {
							}
						}
					}
				}
				&.fixed {
					background: #fff;
					position: fixed;
					top: 0;
					width: 100%;
					z-index: 9999;
					padding: 10px 0;
					@include box-shadow(0 5px 10px rgba(0, 0, 0, .1));
				}
			}
			&.fixed {
				position: fixed;
				top: 0;
				width: 100%;
				z-index: 9999;
				@include box-shadow(0 5px 10px rgba(0, 0, 0, .1));
				@include transition(all 600ms ease-out 0s);
				@include transform(translateY(-5px));
			}
		}
	}
}

/*Header Searchbox Style Two*/
.header-searchbox-style-one {
	position: relative;
	float: right;
	.show-searchbox {
		float: right;
		margin-left: 32px;
		position: relative;
		margin-top: 3px;
		a {
			position: relative;
			width: 35px;
			height: 35px;
			background: $bg-primary-color;
			display: block;
			border-radius: 6px;
			text-align: center;
			line-height: 35px;
			margin: 27px 0 30px;
			i {
				font-size: 14px;
				color: #fff;
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-ms-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
			}
		}
		&.active .fa-search::before {
			content: "\f00d";
		}
	}
	.toggle-searchbox {
		position: absolute;
		right: 0;
		top: 170%;
		width: 315px;
		opacity: 0;
		padding: 20px;
		visibility: hidden;
		@include transition(all 0.3s ease-in-out);
		#searchform-all {
			position: relative;
			#s {
				margin-bottom: 0;
				height: 50px;
				font-size: 14px;
				color: $bg-primary-color;
				top: 6px;
				background-color: #fff;
				border-radius: 0;
			}
			#searchsubmit {
				padding: 3px;
				position: absolute;
				right: 12px;
				top: 9px;
				font-family: ""Font Awesome 5 Free"";
				color: $bg-primary-color;
				background: transparent;
				border: none;
				text-align: center;
				font-size: 16px;
				font-weight: 700;
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-ms-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
			}
		}
		form {
			input[type="text"] {
				border: 1px solid #ddd6d9;
			}
		}
		&.show {
			visibility: visible;
			opacity: 1;
			z-index: 1;
			top: 103%;
			padding: 20px;
			background: #f4f4f4;
			border: 1px solid $bg-primary-color;
		}
	}
}

/*Header Searchbox Style Two*/
.header-searchbox-style-two {
	position: relative;
	float: right;
	margin-top: 6px;
	margin-left: 30px;
	.show-searchbox {
		float: right;
		margin-left: 32px;
		position: relative;
		margin-top: 3px;
		a {
			position: relative;
			width: 40px;
			height: 40px;
			display: block;
			text-align: center;
			line-height: 55px;
			margin: 25px 0 30px;
			i {
				font-size: 20px;
				color: $bg-primary-color;
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-ms-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
			}
		}
		&.active .fa-search::before {
			content: "\f00d";
		}
	}
	.toggle-searchbox {
		position: absolute;
		right: 0;
		top: 170%;
		width: 315px;
		opacity: 0;
		padding: 20px;
		visibility: hidden;
		@include transition(all 0.3s ease-in-out);
		#searchform-all {
			position: relative;
			#s {
				margin-bottom: 0;
				height: 50px;
				font-size: 14px;
				color: $bg-primary-color;
				top: 6px;
				background-color: #fff;
				border-radius: 0;
			}
			#searchsubmit {
				padding: 3px;
				position: absolute;
				right: 12px;
				top: 9px;
				font-family: ""Font Awesome 5 Free"";
				color: $bg-primary-color;
				background: transparent;
				border: none;
				text-align: center;
				font-size: 16px;
				font-weight: 700;
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-ms-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
			}
		}
		form {
			input[type="text"] {
				border: 1px solid #ddd6d9;
			}
		}
		&.show {
			visibility: visible;
			opacity: 1;
			z-index: 2;
			top: 114%;
			padding: 20px;
			background: #f4f4f4;
			border: 1px solid $bg-primary-color;
		}
	}
}
.input-box {
	position: absolute;
	right: 12px;
	top: 12px;
	i {
		color: $bg-primary-color;
	}
}

/*Header-top-area*/
.header-top-area {
	font-size: 14px;
	color: #fff;
	padding: 10px 0;
	a {
		color: #fff;
		font-size: 14px;
		display: inline-block;
		font-weight: 400;
	}
	i {
		margin: 0px;
		font-size: 14px;
		font-weight: 400;
	}
	.header-top-left-part {
		.address {
			padding-right: 20px;
			position: relative;
			&:after {
				background: #fff;
				content: "";
				position: absolute;
				height: 90%;
				width: 1px;
				right: 0;
				top: 2px;
			}
		}
		.phone {
			padding-left: 20px;
		}
		i {
			color: #fff;
			margin-right: 5px;
			vertical-align: middle;
			&:before {
				font-size: 16px;
			}
		}
	}
	.header-top-right-part {
		.social-links {
			position: relative;
			display: inline-block;
			&::after {
				position: absolute;
				content: "";
				right: 10px;
				top: 7px;
				height: 60%;
				width: 1px;
				background: #fff;
			}
			li {
				display: inline-block;
				margin-right: 15px;
				a {
					color: #fff;
					-webkit-transition: .3s;
					transition: .3s;
					&:hover {
						color: $bg-secondary-color;
					}
				}
				&:last-child {
					margin-right: 30px;
				}
			}
		}
		.language {
			display: inline-block;
			margin-left: 25px;
			position: relative;
			.language-btn {
				position: relative;
				text-decoration: none;
				width: 85px;
				text-align: left;
				-webkit-transition: .5s;
				transition: .5s;
				i {
					margin-right: 4px;
					&::before {
						font-size: 14px;
					}
				}
				&:hover {
					color: $bg-secondary-color;
				}
				&::after {
					content: "\f107";
					position: absolute;
					right: -3px;
					font-family: "Font Awesome 5 Free";
					font-weight: 700;
					top: 0;
					font-size: 16px;
				}
			}
			.language-dropdown {
				text-align: center;
				position: absolute;
				z-index: 10;
				top: 36px;
				right: -13px;
				width: 120px;
				visibility: hidden;
				opacity: 0;
				-webkit-transform: translateY(20px);
				transform: translateY(20px);
				-webkit-transition: .5s;
				transition: .5s;
				&.open {
					visibility: visible;
					opacity: 1;
					-webkit-transform: translateY(0px);
					transform: translateY(0px);
					background: #31314d;
				}
				li {
					position: relative;
					background-color: #0a3041;
					z-index: -1;
					a {
						display: block;
						position: relative;
						padding: 5px 10px;
						color: #fff;
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
						text-decoration: none;
						&:hover {
							background: $bg-primary-color;
						}
					}
					:last-child {
						a {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}

/* extra info */
.side-panel-content {
	background: #fff;
	height: 100vh;
	padding: 30px;
	position: fixed;
	right: 0;
	top: 0;
	-webkit-transition: all 0.7s ease 0s;
	-o-transition: all 0.7s ease 0s;
	transition: all 0.7s ease 0s;
	width: 355px;
	z-index: 9999;
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
	padding-bottom: 40px;
}
.side-panel-content.side-panel-open {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
	@include box-shadow(0px 8px 16px 0px rgba(0, 0, 0, 0.3));
}
.close-icon {
	text-align: right;
}
.close-icon > button {
	cursor: pointer;
	background: $bg-primary-color;
	border-radius: 50%;
	height: 42px;
	width: 42px;
}
.close-icon > button i {
	line-height: 20px;
	color: #fff;
}
.close-icon > button i:before {
	font-size: 14px;
}
.extra-info > p {
	color: #ddd;
	font-size: 15px;
	line-height: 28px;
}
.extra-info.extra-info-left.side-panel-open {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}
.side-panel {
	width: 36px;
	float: right;
	padding-top: 38px;
	cursor: pointer;
	margin-left: 10px;
}
.side-panel span {
	margin: 3px 0;
	transition: .3s;
	float: right;
}
.side-panel:hover span {
	width:100%;
}
.bar1 {
	width: 55%;
	background: $bg-primary-color;
	height: 2px;
}
.bar2 {
	width: 75%;
	background: $bg-primary-color;
	height: 2px;
}
.bar3{
	width: 65%;
	background: $bg-primary-color;
	height: 2px;
}