.service-box:hover {
    -moz-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}
.case-study-item:hover .side-line:before {
    width: 65px;
    left: -15px;
    top: 25px;
}

.header-top-area a {
    color: #fff;
    font-size: 14px;
    display: inline-block;
    font-weight: 400;
}
.header-top-area .header-top-right-part .social-links li:last-child {
    margin-right: 30px;
}
.header-top-area .header-top-right-part .social-links::after {
    position: absolute;
    content: "";
    right: 10px;
    top: 7px;
    height: 60%;
    width: 1px;
    background: #fff;
}
.service-box1:after {
    background: url(../img/home/jpg/Medical-device.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.service-box2:after {
    background: url(../img/home/jpg/Pipeline-Analysis-Reports.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.service-box3:after {
    background: url(../img/home/jpg/Indication-Forecast-Reports.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.service-box:hover .service-icon span:before {
    position: relative;
    z-index: 2;
    color: #fff;
}
.service-box-market-research:after {
    background: url(../img/about-us/jpg/Market-Research.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.service-box-business-consulting:after {
    background: url(../img/about-us/jpg/Business-Consulting-service.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.service-box-competitive-intelligence:after {
    background: url(../img/about-us/jpg/Competitive-Intelligence.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.service-box-pharmdelve:after {
    background: url(../img/about-us/jpg/Pharmadelve.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.header-style-two .header-wrapper .header-navigation-area .main-menu ul li {
    margin-right: 35px;
}


  
  /* .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  .popup {
    margin: 230px auto;
    padding: 15px;
    background: #fff;
    width: 45%;
    position: relative;
    transition: all 5s ease-in-out;
    border: 10px solid #000000;
}
  
  .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }
  .popup .close {
    position: absolute;
    top: 5px;
    right: 10px;
    transition: all 200ms;
    font-size: 25px;
    font-weight: normal;
    text-decoration: none;
    color: red;
}
  .popup .close:hover {
    color: #06D85F;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
  }
  
  @media screen and (max-width: 700px){
    .box{
      width: 70%;
    }
    .popup{
      width: 70%;
    }
  } */

  .form-control-new {
    box-shadow: none;
    outline: none;
    border: 1px solid #dfdce6;
    background: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    font-family: "Poppins", sans-serif;
    color: #17305e;
    height: auto;
    padding: 15px 15px;
    resize: none;
    min-width: 100%;
    height: 45px;
}
.form-group-new {
    margin-bottom: 1rem;
}









/* popup styles */
.popup {
      display: none;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
  }
  .popup-overlay {
    background: rgba(0,0,0,0.9);
    position: absolute;
    /* left: 0; */
    /* right: 0; */
    /* top: 0; */
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: overlay;
    z-index: 9999;
  }
  .popup-content {
    position: absolute;
    background: #fff;
    width: 500px;
    margin: -140px 0 0 -264px;
    left: 50%;
    top: 40%;
    z-index: 9999;
    padding: 14px;
  }
  /* .close-popup {
    display: inline-block;
    position: absolute;
    top: -8px;
    right: -30px;
    font-size: 42px;
  } */

  .close-popup {
    position: absolute;
    top: 5px;
    right: 10px;
    transition: all 200ms;
    font-size: 25px;
    font-weight: normal;
    text-decoration: none;
    color: red;
}
  
  /* Animations */
  .fadeIn {
    animation: fadeIn 0.5s ease-in both;
    -webkit-animation: fadeIn 0.5s ease-in both;
  }
  @keyframes fadeIn {
    from { opacity: 0; }
  }
  @-webkit-keyframes fadeIn {
    from { opacity: 0; }
  }
  
  .fadeOut {
    animation: fadeOut 0.5s ease-out both;
    -webkit-animation: fadeOut 0.5s ease-out both;
  }
  @keyframes fadeOut {
    to { opacity: 0; }
  }
  @-webkit-keyframes fadeOut {
    to { opacity: 0; }
  }

  /* Demo Styles */
/* body {
    font-family: verdana;
    font-size: 12px;
    text-align: center;
  }
  a {
    color: rgb(0, 159, 211);
    text-decoration: none;
  } */
  /* .btn {
    background: rgb(0, 159, 211);
    color: #fff;
    border: 1px solid;
    display: inline-block;
    padding: 5px 20px;
  }
  .btn:hover {
    background: #fff;
    color: rgb(0, 159, 211);
  } */
  /* .popup {
      -webkit-perspective: 1200px;
      -moz-perspective: 1200px;
      perspective: 1200px;
  } */
  .popup-content {
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform: translate3d(0,0,0);
      -moz-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
  }
  .header-style-two .header-wrapper .header-navigation-area.fixed {
    z-index: 9;
}
.service-box:hover .service-icon2{
    display:block !important;
}
.service-box:hover .service-icon2 span{
    position: relative;
    z-index: 99;
}
.service-box:hover .service-icon{
    display:none;
}

#scrollUp{
  bottom: 100px !important;
  right:33px !important;
}